import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import { toast } from "react-toastify";

function useCopyToClipboard() {
  const { t } = useTranslation();

  const copy = useCallback(
    async (text: string) => {
      if (!navigator || !navigator.clipboard) return;

      try {
        await navigator.clipboard.writeText(text || "");
        toast(t("sections.manage.copyToClipboard"), {
          type: "success",
        });
      } catch {
        // NO OP
      }
    },
    [t]
  );

  return copy;
}

export default useCopyToClipboard;

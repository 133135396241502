import dynamic from "next/dynamic";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "stores";
import { selectAuthMessageType, selectAuthModalType } from "stores/features/ui";

import { useAuthentication, useClaimApp } from "./utils";

const AuthSignup = dynamic(() => import("./AuthSignup"));
const AuthAddOrg = dynamic(() => import("./AuthAddOrg"));
const AuthAppAccessError = dynamic(() => import("./AuthAppAccessError"));
const AuthChangePassword = dynamic(() => import("./AuthChangePassword"));
const AuthClaimApp = dynamic(() => import("./AuthClaimApp"));
const AuthDeleteApp = dynamic(() => import("./AuthDeleteApp"));
const AuthInviteMember = dynamic(() => import("./AuthInviteMember"));
const AuthLeaveOrganization = dynamic(() => import("./AuthLeaveOrganization"));
const AuthOrgAccessError = dynamic(() => import("./AuthOrgAccessError"));
const AuthOrgInvitation = dynamic(() => import("./AuthOrgInvitation"));
const AuthRegistration = dynamic(() => import("./AuthRegistration"));
const AuthSignin = dynamic(() => import("./AuthSignin"), {
  loading: () => <AuthSignup />,
});
const AuthSignout = dynamic(() => import("./AuthSignout"));
const AuthSignoutPlaceholder = dynamic(
  () => import("./AuthSignoutPlaceholder")
);

const AuthTransferApp = dynamic(() => import("./AuthTransferApp"));
const DashboardDeleteApp = dynamic(() => import("./DashboardDeleteApp"));
const DashboardEmailOptout = dynamic(() => import("./DashboardEmailOptout"));
const ImportFromApp = dynamic(() => import("./ImportFromApp"));
const ShopifyEmailUnavailable = dynamic(
  () => import("./ShopifyEmailUnavailable")
);
const ShopifyRebuild = dynamic(() => import("./ShopifyRebuild"));
const UploadKeystore = dynamic(() => import("./UploadKeystoreModal"));
const ChangeAccount = dynamic(() => import("./ChangeAccount"));

interface Props {
  enabled?: boolean;
}

const AuthContainer: React.FC<Props> = ({ enabled }) => {
  useAuthentication(enabled);

  const dispatch = useAppDispatch();
  const authMessageType = useAppSelector(selectAuthMessageType);
  const authModalType = useAppSelector(selectAuthModalType);

  const { isClaimSection, claimAppWall } = useClaimApp();

  useEffect(() => {
    if (isClaimSection) {
      claimAppWall();
    }
  }, [claimAppWall, dispatch, isClaimSection]);

  if (!enabled) return null;

  return (
    <>
      {authMessageType.startsWith("register") && <AuthRegistration />}
      {authMessageType.startsWith("appAccessError") && <AuthAppAccessError />}
      {authMessageType.startsWith("orgAccessError") && <AuthOrgAccessError />}
      {authMessageType.startsWith("orgInvite") && <AuthOrgInvitation />}
      {authMessageType === "signout" && <AuthSignout />}
      {authMessageType.startsWith("signin") && <AuthSignin isPage />}
      {authMessageType.startsWith("signup") && <AuthSignup isPage />}
      {authMessageType === "addOrg" && <AuthAddOrg isPage />}

      {authModalType.startsWith("signout-placeholder") && (
        <AuthSignoutPlaceholder />
      )}
      {authModalType.startsWith("signup") && <AuthSignup />}
      {authModalType.startsWith("signin") && <AuthSignin />}
      {authModalType === "addOrg" && <AuthAddOrg />}
      {authModalType === "inviteMember" && <AuthInviteMember />}
      {authModalType === "claimApp" && <AuthClaimApp />}
      {authModalType === "transferApp" && <AuthTransferApp />}
      {authModalType === "deleteApp" && <AuthDeleteApp />}
      {authModalType === "leaveOrganization" && <AuthLeaveOrganization />}
      {authModalType === "changePassword" && <AuthChangePassword />}
      {authModalType === "rebuildShopify" && <ShopifyRebuild />}
      {authModalType === "inputEmailShopify" && <ShopifyEmailUnavailable />}
      {authModalType === "uploadKeystore" && <UploadKeystore />}
      {authModalType === "dashboardDeleteApp" && <DashboardDeleteApp />}
      {authModalType === "dashboardEmailOptout" && <DashboardEmailOptout />}
      {authModalType === "importFromApp" && <ImportFromApp />}
      {authModalType === "changeAccount" && <ChangeAccount />}
    </>
  );
};

export default AuthContainer;

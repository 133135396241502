/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "react-use";

import { InputChangeEvent } from "components/common/Input";

interface UseAdvanced<T> {
  advancedMode?: boolean;
  debounce?: number;
  onChange: (output: T) => void;
  processValue?: (value: T) => T;
  value: T;
}

export default function useAdvancedModeV2<T>({
  advancedMode,
  debounce = 1000,
  onChange = () => null,
  processValue = (input: T) => input,
  value,
}: UseAdvanced<T>) {
  const [valueJson, setValueJson] = useState(JSON.stringify(value, null, 2));
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (advancedMode) {
      setValueJson(JSON.stringify(value, null, 2));
    }
  }, [advancedMode, value]);

  const handleCodeChange = useCallback((output: InputChangeEvent) => {
    setValueJson(output.value);
  }, []);

  useDebounce(
    () => {
      try {
        const nextValue = valueJson ? JSON.parse(valueJson) : valueJson;
        onChange(processValue(nextValue));
        setError(undefined);
      } catch (e) {
        setError(e as Error);
      }
    },
    debounce,
    [valueJson]
  );

  return {
    error,
    handleCodeChange,
    valueJson,
  };
}

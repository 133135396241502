import classNames from "classnames";
import React, { forwardRef, useCallback } from "react";

import Loader from "components/common/Loader";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface TextInputProps {
  autoCapitalize?: "characters" | "none" | "off" | "on" | "sentences" | "words";
  autoCorrect?: "on" | "off";
  autoComplete?: string;
  onChange?: (event: InputChangeEvent) => void;
  placeholder?: string;
  type: "email" | "password" | "text";
  value?: string | null;
  maxLength?: number;
  textOnly?: boolean;
  errorMessage?: string;
}

type Props = BaseProps & TextInputProps;

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete,
      autoCapitalize,
      autoCorrect,
      autoFocus,
      className,
      dark,
      disabled,
      id,
      inline,
      label,
      labelClassName,
      labelSize,
      loading,
      maxLength,
      name,
      onBlur = () => null,
      onChange = () => null,
      onFocus = () => null,
      placeholder,
      readOnly,
      required,
      type,
      textOnly,
      value,
      errorMessage,
    },
    ref
  ) => {
    const handleBlur = useCallback(() => {
      onBlur(id);
    }, [id, onBlur]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ id, name, value: event.target.value });
      },
      [id, name, onChange]
    );

    const handleFocus = useCallback(
      (e) => {
        if (!readOnly) onFocus(id, e);
      },
      [id, onFocus, readOnly]
    );

    return (
      <label
        className={classNames(
          styles.container,
          { [styles.inline]: inline },
          className
        )}
        htmlFor={id}
      >
        <InputLabel
          className={classNames(inline && styles.labelInline, labelClassName)}
          size={labelSize}
        >
          {label}
        </InputLabel>

        <div className={styles.inputContainer}>
          <input
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
            autoFocus={autoFocus}
            className={classNames(
              styles.textInput,
              textOnly && styles.textOnly,
              { [styles.dark]: dark }
            )}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            required={required}
            type={type === "email" ? "text" : type}
            value={value || ""}
            maxLength={maxLength}
          />

          {!!errorMessage && (
            <div className={classNames(styles.inputError)}>{errorMessage}</div>
          )}

          <Loader className={styles.inputLoader} disabled={!loading} />
        </div>
      </label>
    );
  }
);

export default TextInput;

import { AppUser } from "stores/features/app";

import { baseAxios } from "./utils";

type UpdateUserAccessArgs =
  | {
      access: string;
      appId: string;
      userEmail: string;
    }
  | {
      access: string;
      appId: string;
      userId: string;
    };

interface AppUsers {
  users: AppUser[];
}
export const updateUserAccess = async (args: UpdateUserAccessArgs) => {
  const { data } = await baseAxios.post<AppUsers>("/account/apps/access", args);
  return data;
};

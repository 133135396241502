import PerfectScrollbar from "perfect-scrollbar";
import { useEffect, useRef } from "react";

function usePerfectScrollbar<T extends HTMLElement>(enabled = true) {
  const ref = useRef<T>(null);
  const perfectScrollbarRef = useRef<PerfectScrollbar | null>(null);

  useEffect(() => {
    try {
      if (ref.current && enabled) {
        perfectScrollbarRef.current = new PerfectScrollbar(ref.current);
        perfectScrollbarRef.current?.update();
      }
    } catch {
      // no op
    }
  }, [enabled]);

  return ref;
}

export default usePerfectScrollbar;

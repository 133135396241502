import { useAppSelector } from "stores";
import { selectIsSuperUser } from "stores/features/user";
import { isDev } from "utils/constants";

const useAnalytics = () => {
  const isSuperUser = useAppSelector(selectIsSuperUser);

  return !isDev && !isSuperUser;
};

export default useAnalytics;

/* eslint-disable prefer-destructuring */
import { GetServerSidePropsContext } from "next";
import { parseCookies } from "nookies";

export function getCookie(name: string) {
  try {
    const cookie = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
    return cookie ? cookie.pop() : null;
  } catch {
    return null;
  }
}

export function setCookie(
  name: string,
  value: string | number | boolean,
  expiration?: number
) {
  try {
    let cookie = `${name}=${value}; path=/`;

    if (expiration) {
      const date = new Date();
      date.setTime(date.getTime() + expiration);
      cookie = `${cookie}; expires=${date.toUTCString()}`;
    }

    document.cookie = cookie;
  } catch {
    // NO OP
  }
}

export function deleteCookie(name: string) {
  try {
    const date = new Date();
    date.setTime(date.getTime() - 1);
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
  } catch {
    // NO OP
  }
}

export function getCookieHeaders(context?: GetServerSidePropsContext) {
  let connectSid: string | undefined | null;
  let clientIp: string | undefined;

  if (context) {
    const cookies = parseCookies({ req: context.req });
    connectSid = cookies["connect.sid"];

    clientIp = context.req.socket.remoteAddress;
    const forwarded = context.req.headers["x-forwarded-for"];
    if (typeof forwarded === "string") {
      clientIp = forwarded;
    }
  }

  return {
    Cookie: `connect.sid=${connectSid || process.env.NEXT_PUBLIC_CONNECT_SID}`,
    "X-Forwarded-For": clientIp || "",
  };
}

import axios from "axios";
import classNames from "classnames";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import Button from "components/common/Button";
import { useAppSelector } from "stores";
import { selectPublicKey } from "stores/features/project";
import api from "utils/api";
import { handleError } from "utils/errors";
import { useImage } from "utils/hooks";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

const ImageInputEditor = dynamic(() => import("./ImageInputModal"));

export interface ImageInputProps {
  buttonClassName?: string;
  editable?: boolean;
  extraButtons?: React.ReactNode;
  filename: string;
  hideImagePreview?: boolean;
  imageClassName?: string;
  imageHeight: number | string;
  imagePlaceholderClassName?: string;
  imageUploadedClassName?: string;
  imageWidth: number | string;
  maxWidth?: number;
  maxHeight?: number;
  onChange?: (event: InputChangeEvent<string>) => void;
  shape?: "round" | "roundedSquare" | "square";
  type: "image";
  value?: string | null;
}

type Props = BaseProps & ImageInputProps;

const ImageInput: React.FC<Props> = ({
  buttonClassName,
  className,
  dark,
  disabled,
  editable,
  extraButtons = null,
  filename,
  hideImagePreview,
  id,
  imageClassName,
  imageHeight,
  imagePlaceholderClassName,
  imageUploadedClassName,
  imageWidth,
  inline,
  label,
  labelClassName,
  labelSize,
  maxHeight,
  maxWidth,
  name,
  onChange = () => null,
  shape = "square",
  value,
}) => {
  const publicKey = useAppSelector(selectPublicKey);

  const [cancelTokenSource, setCancelTokenSource] = useState(
    axios.CancelToken.source()
  );
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const { t } = useTranslation();

  const canvasRef = useRef<HTMLDivElement>(null);

  const handleDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleClose = useCallback(() => {
    setUploadedImage("");
  }, []);

  const handleUpload = useCallback(
    async (file: File) => {
      try {
        const newCancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);
        const response = await api.general.upload({
          publicKey,
          file,
          config: { cancelToken: newCancelTokenSource.token },
        });
        onChange({ id, name, value: decodeURIComponent(response) });
      } catch (e) {
        handleError(e, { t });
      } finally {
        handleClose();
      }
    },
    [publicKey, id, handleClose, name, onChange, t]
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsDragging(false);
      if (acceptedFiles.length === 0) return;
      if (editable) {
        setUploadedImage(URL.createObjectURL(acceptedFiles[0]));
      } else {
        handleUpload(acceptedFiles[0]);
      }
    },
    [editable, handleUpload]
  );

  const dropzone = useDropzone({
    accept: {
      "image/jpg": [".png", ".jpeg", ".jpg"],
    },
    maxFiles: 1,
    multiple: false,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
    noClick: disabled,
    noKeyboard: disabled,
  });

  const handleCancel = useCallback(() => {
    cancelTokenSource.cancel();
    handleClose();
  }, [cancelTokenSource, handleClose]);

  const imageValue = useImage(value);

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.imageInline]: inline },
        className
      )}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        size={labelSize}
      >
        {label}
      </InputLabel>

      <div className={styles.imageInputButtonContainer}>
        {value && (
          <Button
            className={classNames(styles.imageInputButton, buttonClassName)}
            onClick={dropzone.open}
            type="outlined"
            disabled={disabled}
          >
            {t("components.inputs.changeImage")}
          </Button>
        )}
        {extraButtons}
      </div>

      {!hideImagePreview && (
        <div
          {...dropzone.getRootProps({
            className: classNames(
              styles.imageInput,
              dark && styles.darkBackground,
              {
                [styles.dragging]: isDragging,
                [styles.round]: shape === "round",
                [styles.roundedSquare]: shape === "roundedSquare",
                [styles.uploaded]: !!value && !disabled,
                [styles.disabled]: disabled,
              },
              imageClassName,
              !!value && imageUploadedClassName
            ),
            style: {
              height: imageHeight,
              width: imageWidth,
              maxHeight,
              maxWidth,
            },
          })}
        >
          {imageValue && (
            <img alt="" className={styles.imageInputValue} src={imageValue} />
          )}
          <input
            {...dropzone.getInputProps({ id, name })}
            disabled={disabled}
          />
          {!value && (
            <span
              className={classNames(
                styles.imageInputPlaceholder,
                imagePlaceholderClassName
              )}
            >
              {t("components.inputs.uploadImage")}
            </span>
          )}
        </div>
      )}

      {hideImagePreview && (
        <input {...dropzone.getInputProps({ id, name })} disabled={disabled} />
      )}

      {uploadedImage && (
        <ImageInputEditor
          cropRef={canvasRef}
          filename={filename}
          image={uploadedImage}
          onCancel={handleCancel}
          onClose={handleClose}
          onSaveFinish={handleUpload}
          shape={shape}
        />
      )}
    </div>
  );
};

export default ImageInput;

type ContactData = {
  email?: string;
  name?: string;
  recent_buildfailure_app?: string;
  recent_licensed_app_link?: { link?: string; text?: string };
  recent_unlicensed_app_link?: { link?: string; text?: string };
  user_category?: string;
  recent_support_plan?: string;
  recent_supported_app?: { link?: string; text?: string };
  recent_enterprise_app?: { link?: string; text?: string };
  recent_fullservice_app?: { link?: string; text?: string };
};

declare global {
  interface Window {
    groove?: {
      widget: {
        open: () => void;
        close: () => void;
        setContact: (data: ContactData) => void;
      };
    };
  }
}

export type GrooveLinkType = {
  link?: string;
  text?: string;
};

export function closeGroove() {
  if (
    window.groove &&
    window.groove.widget &&
    window.groove.widget.close &&
    typeof window.groove.widget.close === "function"
  ) {
    window.groove.widget.close();
  }
}

export function setGrooveContact(data: ContactData) {
  if (
    window.groove &&
    window.groove.widget &&
    window.groove.widget.setContact &&
    typeof window.groove.widget.setContact === "function"
  ) {
    window.groove.widget.setContact(data);
  }
}

export function setGrooveEmail(email = "") {
  if (typeof email === "string") {
    setGrooveContact({ email });
  }
}

export function setGrooveName(name = "") {
  if (typeof name === "string") {
    setGrooveContact({ name });
  }
}

export function setGrooveBuildFailureApp(recent_buildfailure_app = "") {
  if (typeof recent_buildfailure_app === "string") {
    setGrooveContact({
      recent_buildfailure_app,
    });
  }
}

export function setGrooveLicensedApp(recent_licensed_app_link = {}) {
  if (typeof recent_licensed_app_link === "object") {
    setGrooveContact({ recent_licensed_app_link });
  }
}

export function setGrooveUnlicensedApp(recent_unlicensed_app_link = {}) {
  if (typeof recent_unlicensed_app_link === "object") {
    setGrooveContact({ recent_unlicensed_app_link });
  }
}

export function setGrooveUserCategory(user_category = "") {
  if (typeof user_category === "string") {
    setGrooveContact({ user_category });
  }
}

export function setGrooveSupportPlan(recent_support_plan = "") {
  if (typeof recent_support_plan === "string") {
    setGrooveContact({ recent_support_plan });
  }
}

export function setGrooveSupportedApp(recent_supported_app = {}) {
  if (typeof recent_supported_app === "object") {
    setGrooveContact({ recent_supported_app });
  }
}

export function setGrooveEnterpriseApp(recent_enterprise_app = {}) {
  if (typeof recent_enterprise_app === "object") {
    setGrooveContact({ recent_enterprise_app });
  }
}

export function setGrooveFullServiceApp(recent_fullservice_app = {}) {
  if (typeof recent_fullservice_app === "object") {
    setGrooveContact({ recent_fullservice_app });
  }
}

export function activateSupport(source?: {
  buildFailureApp?: string;
  email?: string;
  subject?: string;
}) {
  const {
    buildFailureApp,
    email,
    subject = "Median Website Inquiry",
  } = source || {};

  if (
    window.groove &&
    window.groove.widget &&
    window.groove.widget.open &&
    typeof window.groove.widget.open === "function"
  ) {
    setGrooveEmail(email);
    setGrooveBuildFailureApp(buildFailureApp);
    window.groove.widget.open();
  } else {
    /*
  else if (
    window.HubSpotConversations &&
    window.HubSpotConversations.widget &&
    window.HubSpotConversations.widget.open &&
    typeof window.HubSpotConversations.widget.open === "function"
  ) {
    window.HubSpotConversations.widget.open();
  } 
  */
    window.location.href = `mailto:hello@gonative.io?subject=${window.encodeURI(
      subject
    )}`;
  }
}

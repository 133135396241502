/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */

import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isColorAlpha } from "utils/colors";

import { Color, ColorPickerCallback } from "./constants";
import styles from "./styles.module.scss";
import { roundFloat, toColor, toHsv, toRgb, validHex } from "./utils";

export interface FieldsProps {
  readonly color: Color;
  readonly hideHEX?: boolean;
  readonly hideRGB?: boolean;
  readonly hideHSV?: boolean;
  readonly alpha: boolean;
  readonly onChange: ColorPickerCallback;
  readonly suggestedColors?: string[];
}

const HexField: React.FC<FieldsProps> = ({ color, alpha, onChange }) => {
  const { t } = useTranslation();

  const getValueHEX = useCallback(
    () => ({ value: color.hex, inputted: false }),
    [color.hex]
  );

  const [valueHEX, setValueHEX] = useState(getValueHEX);

  useEffect(() => {
    if (!valueHEX.inputted) {
      setValueHEX(getValueHEX);
    }
    if (!alpha && valueHEX.value.length > 7) {
      onChange(toColor("hex", valueHEX.value.substring(0, 7)));
      setValueHEX({ ...valueHEX });
    }
  }, [valueHEX.inputted, getValueHEX]);

  const changeHEX = useCallback(
    (e) => {
      const { value } = e.target;

      // Special case when user tried to type "TRANSPARENT"
      if (value.toLowerCase() === "transparent") {
        onChange(toColor("hex", "#00000000"));
        setValueHEX({ ...valueHEX, value: "#00000000" });
      } else if (validHex(value)) {
        onChange(toColor("hex", alpha ? value : value.substring(0, 7)));
        setValueHEX({ ...valueHEX, value });
      }
    },
    [onChange, valueHEX]
  );

  const handleFocus = useCallback(
    (e) => {
      setValueHEX({ ...valueHEX, inputted: true });
      e.target.select();
    },
    [valueHEX]
  );

  const handleBlur = useCallback(() => {
    setValueHEX({ ...valueHEX, inputted: false });
  }, [valueHEX]);

  return (
    <div className={classNames(styles.rcpFieldsElement, styles.hexElement)}>
      <label className={styles.rcpFieldsElementLabel} htmlFor="color">
        {t("components.colorPicker.hex")}
      </label>
      <input
        className={styles.rcpFieldsElementInput}
        value={valueHEX.value}
        onFocus={handleFocus}
        onChange={changeHEX}
        onBlur={handleBlur}
      />
    </div>
  );
};

const HsvField: React.FC<FieldsProps> = ({ color, alpha, onChange }) => {
  const { t } = useTranslation();

  const getValueHSV = useCallback(
    () => ({
      value: `${Math.round(color.hsv.h)}°, ${Math.round(
        color.hsv.s
      )}%, ${Math.round(color.hsv.v)}%${
        alpha && color.hsv.a !== undefined
          ? `, ${roundFloat(color.hsv.a, 3)}`
          : ""
      }`,
      inputted: false,
    }),
    [color.hsv, alpha]
  );

  const [valueHSV, setValueHSV] = useState(getValueHSV);

  useEffect(() => {
    if (!valueHSV.inputted) {
      setValueHSV(getValueHSV);
    }
  }, [valueHSV.inputted, getValueHSV]);

  const changeHSV = useCallback(
    (e) => {
      const value = e.target.value.match(/\d+(?:\.\d+)?/g);

      if (value && (value.length === 3 || (alpha && value.length === 4))) {
        const hsb = toHsv(value);

        onChange(toColor("hsv", hsb));
      }

      setValueHSV({ ...valueHSV, value: e.target.value });
    },
    [alpha, onChange, valueHSV]
  );

  const handleFocus = useCallback(
    (e) => {
      setValueHSV({ ...valueHSV, inputted: true });
      e.target.select();
    },
    [valueHSV]
  );

  const handleBlur = useCallback(() => {
    setValueHSV({ ...valueHSV, inputted: false });
  }, [valueHSV]);

  return (
    <div className={styles.rcpFieldsElement}>
      <label className={styles.rcpFieldsElementLabel} htmlFor="color">
        {t("components.colorPicker.hsv")}
      </label>
      <input
        className={styles.rcpFieldsElementInput}
        value={valueHSV.value}
        onFocus={handleFocus}
        onChange={changeHSV}
        onBlur={handleBlur}
      />
    </div>
  );
};

const RgbField: React.FC<FieldsProps> = ({ color, alpha, onChange }) => {
  const { t } = useTranslation();

  const getValueRGB = useCallback(
    () => ({
      value: `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}${
        alpha && color.rgb.a !== undefined
          ? `, ${roundFloat(color.rgb.a, 3)}`
          : ""
      }`,
      inputted: false,
    }),
    [color.rgb, alpha]
  );

  const [valueRGB, setValueRGB] = useState(getValueRGB);

  useEffect(() => {
    if (!valueRGB.inputted) {
      setValueRGB(getValueRGB);
    }
  }, [valueRGB.inputted, getValueRGB]);

  const changeRGB = useCallback(
    (e) => {
      const value = e.target.value.match(/\d+(?:\.\d+)?/g);

      if (value && (value.length === 3 || (alpha && value.length === 4))) {
        const rgb = toRgb(value);

        onChange(toColor("rgb", rgb));
      }

      setValueRGB({ ...valueRGB, value: e.target.value });
    },
    [alpha, onChange, valueRGB]
  );

  const handleFocus = useCallback(
    (e) => {
      setValueRGB({ ...valueRGB, inputted: true });
      e.target.select();
    },
    [valueRGB]
  );

  const handleBlur = useCallback(() => {
    setValueRGB({ ...valueRGB, inputted: false });
  }, [valueRGB]);

  return (
    <div className={styles.rcpFieldsElement}>
      <label className={styles.rcpFieldsElementLabel} htmlFor="color">
        {t("components.colorPicker.rgb")}
      </label>
      <input
        className={styles.rcpFieldsElementInput}
        value={valueRGB.value}
        onFocus={handleFocus}
        onChange={changeRGB}
        onBlur={handleBlur}
      />
    </div>
  );
};

const SuggestedColors: React.FC<FieldsProps> = ({
  onChange,
  alpha,
  suggestedColors = [],
}) => {
  const { t } = useTranslation();

  const suggestedColorsUpdated: string[] = useMemo(() => {
    const values = suggestedColors.filter(
      (value) => alpha || !isColorAlpha(value)
    );
    return Array.from({ length: 10 }, (_, i) => values[i] || "");
  }, [suggestedColors]);

  if (!suggestedColorsUpdated.some((value) => value !== "")) {
    return null;
  }

  return (
    <div className={classNames(styles.rcpFieldsElement, styles.suggested)}>
      <label className={styles.rcpFieldsElementLabel} htmlFor="color">
        {t("components.colorPicker.lastUsedColors")}
      </label>

      <div className={styles.rcpFieldsSuggestions}>
        {suggestedColorsUpdated.map((hexColor, index) => (
          <button
            className={styles.rcpFieldsSuggestionItem}
            disabled={!hexColor}
            onClick={() => onChange(toColor("hex", hexColor))}
            style={{ backgroundColor: hexColor || "transparent" }}
            type="button"
            key={index}
          >
            <div />
          </button>
        ))}
      </div>
    </div>
  );
};

const ColorPickerFields: React.FC<FieldsProps> = ({
  color,
  hideHEX,
  hideRGB,
  hideHSV,
  alpha,
  onChange,
  suggestedColors,
}) => {
  if (hideHEX && hideRGB && hideHSV) return null;

  return (
    <div className={styles.rcpFieldsContainer}>
      <div className={styles.rcpFields}>
        {!hideHEX && (
          <HexField alpha={alpha} color={color} onChange={onChange} />
        )}
        {!hideRGB && (
          <RgbField alpha={alpha} color={color} onChange={onChange} />
        )}
        {!hideHSV && (
          <HsvField alpha={alpha} color={color} onChange={onChange} />
        )}
      </div>

      <SuggestedColors
        alpha={alpha}
        color={color}
        onChange={onChange}
        suggestedColors={suggestedColors}
      />
    </div>
  );
};

export default ColorPickerFields;

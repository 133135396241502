import React, { useCallback } from "react";

import ColorPickerInteractive from "./ColorPickerInteractive";
import { Color, ColorPickerCallback } from "./constants";
import styles from "./styles.module.scss";
import { toColor } from "./utils";

export interface HueProps {
  huePosition: number;
  setHuePosition: (value: number) => void;
  readonly width: number;
  readonly color: Color;
  readonly onChange: ColorPickerCallback;
  readonly onChangeComplete?: ColorPickerCallback;
}

const ColorPickerHue: React.FC<HueProps> = ({
  huePosition,
  setHuePosition,
  width,
  color,
  onChange,
  onChangeComplete,
}) => {
  const updateColor = useCallback(
    ({ x, complete = false }) => {
      const newColor = toColor("hsv", { ...color.hsv, h: (x / width) * 360 });
      onChange(newColor);
      setHuePosition(x);
      if (complete && onChangeComplete) onChangeComplete(newColor);
    },
    [color.hsv, onChange, onChangeComplete, setHuePosition, width]
  );

  return (
    <ColorPickerInteractive className={styles.rcpHue} onChange={updateColor}>
      <div
        className={styles.rcpHueCursor}
        style={{
          left: huePosition,
          backgroundColor: `hsl(${(huePosition / width) * 360}, 100%, 50%)`,
        }}
      />
    </ColorPickerInteractive>
  );
};

export default ColorPickerHue;

import { useMemo } from "react";

import { useAppSelector } from "stores";
import {
  OrganizationInvitedMember,
  OrganizationMember,
  selectIsOrganizationOwner,
  selectOrganizationId,
  selectOrganizationInvitedMembers,
  selectOrganizationMembers,
  selectOrganizationName,
} from "stores/features/organization";
import {
  selectIsSuperUser,
  selectUserOrganizations,
} from "stores/features/user";

interface OrganizationDetailsType {
  organizationId?: string;
  organizationName?: string;
  organizationMembers: OrganizationMember[];
  organizationInvitedMembers: OrganizationInvitedMember[];
  isOrganizationOwner?: boolean;
}

const useOrganizationDetails = (): OrganizationDetailsType => {
  const organizationId = useAppSelector(selectOrganizationId);
  const organizations = useAppSelector(selectUserOrganizations);
  const organizationName = useAppSelector(selectOrganizationName);
  const organizationMembers = useAppSelector(selectOrganizationMembers);
  const organizationInvitedMembers = useAppSelector(
    selectOrganizationInvitedMembers
  );
  const isOrganizationOwner = useAppSelector(selectIsOrganizationOwner);
  const isSuperUser = useAppSelector(selectIsSuperUser);

  const organization = useMemo(
    () => isSuperUser || organizations.some((i) => i.id === organizationId),
    [isSuperUser, organizationId, organizations]
  );

  if (!organization)
    return {
      organizationMembers: [],
      organizationInvitedMembers: [],
    };

  return {
    organizationId,
    organizationName,
    organizationMembers,
    organizationInvitedMembers,
    isOrganizationOwner,
  };
};

export default useOrganizationDetails;

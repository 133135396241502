import { baseAxios } from "./utils";

export interface ContactFormArgs {
  firstName: string;
  lastName: string;
  topic: string;
  company?: string;
  email: string;
  phone?: string;
  message: string;
  currentPage?: string;
}

export const contactUs = async (args: ContactFormArgs) => {
  const { data } = await baseAxios.post("/contact/contact-form", args);
  return data;
};

import { useMemo } from "react";

import { useGetCustomIconsQuery } from "services/api/general";
import { useAppSelector } from "stores";
import { selectBranding } from "stores/features/appBuilder/branding";
import { selectCustomIcons } from "stores/features/ui";
import { getSignedUrl } from "utils/s3";

const useCustomIcons = () => {
  const branding = useAppSelector(selectBranding);
  const customIcons = useAppSelector(selectCustomIcons);

  const customIconsCss = useMemo(
    () => getSignedUrl(branding.customIcons?.css),
    [branding.customIcons?.css]
  );

  useGetCustomIconsQuery(customIconsCss, { skip: !customIconsCss });

  return customIcons;
};

export default useCustomIcons;

import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo, useState } from "react";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";
import CodeEditor from "./CodeEditor";

export interface CodeInputProps {
  autoCollapse?: boolean;
  borderless?: boolean;
  collapsedHeight?: string;
  editLimit?: number;
  height?: string;
  language: "css" | "html" | "javascript" | "json" | "xml";
  minLines?: number;
  onChange?: (event: InputChangeEvent) => void;
  onFocus?: (id: string | undefined) => void;
  placeholder?: string;
  type: "code";
  value?: string | null;
}

type Props = BaseProps & CodeInputProps;

const CodeInput: React.FC<Props> = ({
  autoCollapse,
  borderless,
  className,
  collapsedHeight = "55px",
  editLimit = 0,
  height = "300px",
  id,
  inline,
  label,
  labelClassName,
  labelSize,
  language,
  minLines,
  name,
  onBlur = () => null,
  onChange = () => null,
  onFocus = () => null,
  placeholder,
  readOnly,
  value,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const editorHeight = useMemo(() => {
    if (autoCollapse && !isFocused) return collapsedHeight;
    return height;
  }, [autoCollapse, collapsedHeight, height, isFocused]);

  const internalValue = useMemo(() => {
    if (!isFocused && !value) {
      return placeholder;
    }

    return value;
  }, [isFocused, placeholder, value]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    onBlur(id);
  }, [id, onBlur]);

  const handleChange = useCallback(
    (nextValue: string) => {
      onChange({ id, name, value: nextValue });
    },
    [id, name, onChange]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);

    onFocus(id);
  }, [id, onFocus]);

  return (
    <label
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
      htmlFor={id}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        size={labelSize}
      >
        {label}
      </InputLabel>

      {editLimit > 0 && (internalValue || "").length > editLimit ? (
        <div
          className={classNames(styles.codeArea, styles.editLimit)}
          style={{ height }}
        >
          {t("components.inputs.codeInputEditLimit", { limit: editLimit })}
        </div>
      ) : (
        <CodeEditor
          className={classNames(
            styles.codeArea,
            internalValue === placeholder && styles.withPlaceholder,
            borderless && styles.borderless
          )}
          editorProps={{ $blockScrolling: true }}
          height={editorHeight}
          mode={language}
          name={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          readOnly={readOnly}
          showPrintMargin={false}
          setOptions={{
            useWorker: false,
          }}
          tabSize={2}
          theme="tomorrow"
          value={internalValue || ""}
          width="100%"
          wrapEnabled
          minLines={minLines}
        />
      )}
    </label>
  );
};

export default CodeInput;

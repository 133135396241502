import { deepEqual } from "fast-equals";
import { useCallback, useEffect, useState } from "react";
import { useDebounce, usePrevious, useUnmount } from "react-use";

import { InputChangeEvent } from "components/common/Input";

export interface UseAdvancedMode<T> {
  advancedMode?: boolean;
  debounce?: number;
  onChange: (output: T) => void;
  processValue?: (value: T) => T;
  value: T;
}

export default function useAdvancedMode<T>({
  advancedMode,
  debounce = 200,
  onChange = () => null,
  processValue,
  value,
}: UseAdvancedMode<T>) {
  const [isInitialized, setIsInitialized] = useState(false);
  const prevAdvancedMode = usePrevious(advancedMode);
  const prevValue = usePrevious(value);
  const [valueDebounced, setValueDebounced] = useState("");
  const [valueJson, setValueJson] = useState("");
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (isInitialized) return;

    if (processValue) {
      onChange(processValue(value));
      setIsInitialized(true);
    }
  }, [isInitialized, onChange, processValue, value]);

  useEffect(() => {
    if (processValue) {
      onChange(processValue(value));
    }
  }, [onChange, processValue, value]);

  const handleJsonChange = useCallback(
    (output: string) => {
      try {
        if (!output) return;
        const nextOutput = output ? JSON.parse(output) : output;
        onChange(processValue ? processValue(nextOutput) : nextOutput);
        setError(undefined);
      } catch (e) {
        setError(e as Error);
      }
    },
    [onChange, processValue]
  );

  const [isReady, cancelCodeDebounce] = useDebounce(
    () => {
      handleJsonChange(valueDebounced);
    },
    debounce,
    [handleJsonChange, valueDebounced]
  );

  useEffect(() => {
    if (advancedMode && !prevAdvancedMode) {
      setValueJson(JSON.stringify(value, null, 2));
    }
  }, [advancedMode, prevAdvancedMode, value]);

  useEffect(() => {
    if (!deepEqual(value, prevValue)) {
      cancelCodeDebounce();
      setValueJson(JSON.stringify(value, null, 2));
    }
  }, [cancelCodeDebounce, prevValue, value]);

  const handleCodeBlur = useCallback(() => {
    cancelCodeDebounce();
    handleJsonChange(valueJson);
  }, [cancelCodeDebounce, handleJsonChange, valueJson]);

  useUnmount(() => {
    if (!isReady()) {
      handleCodeBlur();
    }
  });

  useEffect(() => {
    if (advancedMode === prevAdvancedMode) return;

    handleCodeBlur();
  }, [advancedMode, handleCodeBlur, prevAdvancedMode]);

  const handleCodeChange = useCallback((output: InputChangeEvent) => {
    setValueDebounced(output.value);
    setValueJson(output.value);
  }, []);

  return {
    error,
    handleCodeBlur,
    handleCodeChange,
    valueJson,
  };
}

/* eslint-disable react/no-danger */
import { useRouter } from "next/router";
import React, { useCallback } from "react";
import urlJoin from "url-join";

import { useClaimApp } from "components/containers/AuthContainer/utils";
import { useAppDispatch, useAppSelector } from "stores";
import { BannerItem } from "stores/features/appBuilder/banners";
import { selectPrivateKey } from "stores/features/project";
import {
  selectIsRightPanelCollapsed,
  uiShowDocsKeyUpdated,
  uiUpdated,
} from "stores/features/ui";
import { useMobile } from "utils/hooks";
import urls from "utils/urls";

import Button from "../Button";
import IconAnnouncement from "./icons/announcement.svg";
import IconClose from "./icons/close.svg";
import IconError from "./icons/error.svg";
import IconInfo from "./icons/info.svg";
import IconSuccess from "./icons/success.svg";
import IconWarning from "./icons/warning.svg";
import NotificationContainer, {
  NotificationType,
} from "./NotificationContainer";
import styles from "./styles.module.scss";
import { useNotificationBanners } from "./utils";

const icons: Record<string, React.ReactNode> = {
  announcement: <IconAnnouncement />,
  error: <IconError />,
  success: <IconSuccess />,
  warning: <IconWarning />,
};

const NotificationBanners: React.FC = () => {
  const dispatch = useAppDispatch();
  const privateKey = useAppSelector(selectPrivateKey);
  const isRightPanelCollapsed = useAppSelector(selectIsRightPanelCollapsed);
  const isMobile = useMobile();
  const router = useRouter();
  const { claimAppWall } = useClaimApp();

  const { banners, closeBanner } = useNotificationBanners();

  const onButtonClick = useCallback(
    (banner: BannerItem) => {
      if (!banner.button || !banner.button.uri) {
        return;
      }

      if (banner.button.action === "internal") {
        if (banner.button.uri === "claim") {
          claimAppWall();
          return;
        }
        const internalUrl = urlJoin(
          urls.appBuilder(privateKey),
          banner.button.uri
        );
        router.push(internalUrl, undefined, { shallow: true });
        return;
      }

      if (banner.button.action === "docs") {
        const docsUrl = urls.documentation(banner.button.uri);
        dispatch(uiUpdated({ showDocs: docsUrl }));
        dispatch(uiShowDocsKeyUpdated());
        return;
      }

      window.open(banner.button.uri, "_blank");
    },
    [claimAppWall, dispatch, privateKey, router]
  );

  if (isMobile && !isRightPanelCollapsed) {
    return null;
  }

  return (
    <>
      {banners.slice(0, 2).map((banner) => {
        const { id, message, button = {} } = banner;
        const type = id.includes("announcement") ? "announcement" : banner.type;

        return (
          <NotificationContainer
            key={id}
            borderless={type === "announcement"}
            type={(type || "info") as NotificationType}
          >
            <div className={styles.bannerContent}>
              <div className={styles.bannerIcon}>
                {icons[type] || <IconInfo />}
              </div>
              <div className={styles.messageAndButtonContainer}>
                <div
                  className={styles.bannerMessage}
                  dangerouslySetInnerHTML={{ __html: message }}
                />

                {button.label && button.uri && (
                  <Button
                    onClick={() => onButtonClick(banner)}
                    buttonClassName={styles.buttonMobile}
                    type="primary"
                    size="sm"
                  >
                    {button.label}
                  </Button>
                )}
              </div>
            </div>

            <div className={styles.bannerButtons}>
              {button.label && button.uri && (
                <Button
                  onClick={() => onButtonClick(banner)}
                  buttonClassName={styles.button}
                  type="primary"
                  size="md"
                >
                  {button.label}
                </Button>
              )}

              <Button
                className={styles.closeIcon}
                onClick={() => closeBanner(banner)}
              >
                <IconClose />
              </Button>
            </div>
          </NotificationContainer>
        );
      })}
    </>
  );
};

export default NotificationBanners;

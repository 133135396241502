import { useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";

const useIsBasicLicense = () => {
  const license = useAppSelector(selectLicense);
  const status = license.app.status || "";

  return ["active", "nonProduction"].includes(status);
};

export default useIsBasicLicense;

/* eslint-disable jsx-a11y/media-has-caption */

import useTranslation from "next-translate/useTranslation";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import IconPlay from "assets/images/icons/playSound.svg";
import IconPause from "assets/images/icons/stopSound.svg";
import IconTrash from "assets/images/icons/trash.svg";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import { getSignedUrl } from "utils/s3";

import styles from "./styles.module.scss";

export interface Props {
  disabled?: boolean;
  onRemove?: () => void;
  src?: string | null;
}

const FileInputAudio: React.FC<Props> = ({
  disabled,
  onRemove = () => null,
  src,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setIsPlaying(false);
  }, [src]);

  const source = useMemo(() => getSignedUrl(src || ""), [src]);

  const filename = useMemo(() => {
    if (!src) {
      return "";
    }
    const paths = decodeURIComponent(src).split("/");
    return paths[paths.length - 1];
  }, [src]);

  const handlePlay = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  }, []);

  const handlePause = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  if (!filename || !src) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.filename}
        onClick={() => window.open(source, "_blank")}
        type="link"
      >
        {filename}
      </Button>

      <audio
        ref={audioRef}
        src={source}
        onEnded={() => setIsPlaying(false)}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
      />

      {isPlaying ? (
        <Tooltip label={t("common.pause")} placement="bottom">
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={handlePause}
          >
            <IconPause />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip label={t("common.play")} placement="bottom">
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={handlePlay}
          >
            <IconPlay />
          </Button>
        </Tooltip>
      )}

      <Tooltip label={t("common.remove")} placement="bottom">
        <Button
          className={styles.button}
          disabled={disabled}
          onClick={onRemove}
        >
          <IconTrash />
        </Button>
      </Tooltip>
    </div>
  );
};

export default FileInputAudio;

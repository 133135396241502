import React, { SVGProps } from "react";

const IconRadioUncheck: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33337 12C3.33337 16.9523 7.38109 21 12.3334 21C17.2857 21 21.3334 16.9523 21.3334 12C21.3334 7.04772 17.2857 3 12.3334 3C7.38109 3 3.33337 7.04772 3.33337 12Z"
      stroke="#05092B"
      strokeOpacity="0.25"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export default IconRadioUncheck;

import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  htmlFor?: string;
  label?: boolean;
  size?: "lg" | "md";
}

const InputLabel: React.FC<Props> = ({
  children,
  className,
  htmlFor,
  label,
  size = "md",
}) => {
  if (!children) {
    return null;
  }

  return label ? (
    <label
      className={classNames(
        styles.label,
        size === "lg" && styles.large,
        className
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  ) : (
    <span
      className={classNames(
        styles.label,
        size === "lg" && styles.large,
        className
      )}
    >
      {children}
    </span>
  );
};

export default InputLabel;

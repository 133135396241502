/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApiGetProject } from "services/api/projects";
import { getSignedUrl } from "utils/s3";

import { baseAxios } from "./utils";

interface APIGetFontelloSessionArg {
  json?: string;
  privateKey: string;
  returnUrl: string;
}

interface APIGetFontelloSession {
  redirectionUrl: string;
}

export const getFontelloSession = async (arg: APIGetFontelloSessionArg) => {
  const { json, privateKey, returnUrl } = arg;
  let config: any | undefined;

  const jsonUrl = getSignedUrl(json);

  if (jsonUrl) {
    try {
      const response = await fetch(jsonUrl);
      config = await response.json();
    } catch {
      // NO OP
    }
  }

  const { data } = await baseAxios.post<APIGetFontelloSession>(
    `fontello/${privateKey}`,
    { config, returnUrl }
  );
  return data;
};

export const getBrandingCustomIcons = async (privateKey: string) => {
  const { data } = await baseAxios.get<ApiGetProject>(`project/${privateKey}`);
  return data.appBuilder.appBuilder.branding.customIcons;
};

import urlJoin from "url-join";

import urls from "utils/urls";

import { baseAxios } from "./utils";

interface AddMediaSource {
  file: File;
  connectionString: string;
  sessionToken: string;
}

export const addMedia = async (source: AddMediaSource) => {
  const { file, connectionString, sessionToken } = source;

  if (!file || !connectionString || !sessionToken) {
    return null;
  }

  const url = `${connectionString}/session/${sessionToken}/addMedia`;

  const response = await fetch(url, {
    body: file,
    headers: { "Content-Type": file.type },
    method: "POST",
  });

  return response;
};

interface GetAppetizeAccess {
  status: string;
  sessionDuration: number;
}

export const getAppetizeSessionDuration = async (key: string) => {
  const url = urlJoin(urls.base, `project/${key}/appetize-access`);
  const response = await baseAxios.get<GetAppetizeAccess>(url);
  return response.data.sessionDuration;
};

import React, { useCallback, useMemo } from "react";

import ColorPickerInteractive from "./ColorPickerInteractive";
import { Color, ColorPickerCallback } from "./constants";
import styles from "./styles.module.scss";
import { getColorCoordinates, toColor } from "./utils";

export interface SaturationProps {
  hue: number;
  readonly width: number;
  readonly height: number;
  readonly color: Color;
  readonly onChange: ColorPickerCallback;
  readonly onChangeComplete?: ColorPickerCallback;
}

const ColorPickerSaturation: React.FC<SaturationProps> = ({
  hue,
  width,
  height,
  color,
  onChange,
  onChangeComplete,
}) => {
  const position = useMemo(() => {
    const [x, y] = getColorCoordinates(color, width, height);
    return { x, y };
  }, [color, width, height]);

  const updateColor = useCallback(
    ({ x, y, complete = false }) => {
      const newColor = toColor("hsv", {
        ...color.hsv,
        h: hue,
        s: (x / width) * 100,
        v: 100 - (y / height) * 100,
      });
      onChange(newColor);
      if (complete && onChangeComplete) onChangeComplete(newColor);
    },
    [color.hsv, height, hue, onChange, onChangeComplete, width]
  );

  return (
    <ColorPickerInteractive
      className={styles.rcpSaturation}
      style={{ height, backgroundColor: `hsl(${hue}, 100%, 50%)` }}
      onChange={updateColor}
    >
      <div
        className={styles.rcpSaturationCursor}
        style={{
          left: position.x,
          top: position.y,
          backgroundColor: color.hex,
        }}
      />
    </ColorPickerInteractive>
  );
};

export default ColorPickerSaturation;

import { UserOrganization } from "stores/features/user";

import type { CartState } from "stores/features/cart";

export interface StorageCart extends Partial<CartState> {
  cartPrice?: number;
  cartSessionId?: string | null;
  purchasedAt?: string | null;
  supportPrice?: number;
  updatedAt?: string;
}

export interface StorageAuth {
  name: string;
  firstName: string;
  lastName: string;
  backgroundColor: string;
  email: string;
  organizations: UserOrganization[];
  accountUrl: string;
}

interface CartStorageKeys {
  mode: "appBuilder" | "cart" | "pricing";
  privateKey?: string;
}

interface AppStorageKeys {
  licensed: boolean;
}

export interface StorageEmail {
  email: string;
}

export interface StorageRebuildOnSave {
  rebuildOnSave: boolean;
}

export const storageKeys = {
  cart: ({ mode, privateKey }: CartStorageKeys) =>
    mode === "pricing" ? "cart-pricing" : `cart-appBuilder-${privateKey}`,
  app: ({ licensed }: AppStorageKeys) =>
    licensed ? "recent_licensed_app_link" : "recent_unlicensed_app_link",
  userCategory: () => "user_category",
  supportPlan: () => "recent_support_plan",
  supportedApp: () => "recent_supported_app",
  enterpriseApp: () => "recent_enterprise_app",
  fullserviceApp: () => "recent_fullservice_app",
  auth: () => "user-account",
  email: () => "user-email",
  activeCart: () => "active-cart",
  rebuildOnSave: () => "rebuild_on_save",
  userIdGA4Analytics: () => "user_id_ga4_analytics",
};

import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";

import useCustomIcons from "components/common/IconPicker/utils";
import { useAppDispatch, useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";
import {
  selectAppName,
  selectEmail,
} from "stores/features/appBuilder/overview";
import { selectIsCartLoaded } from "stores/features/cart";
import { selectPrivateKey } from "stores/features/project";
import { selectIsFooterVisible, uiUpdated } from "stores/features/ui";
import { selectFirstName, selectIsAuthenticated } from "stores/features/user";
import { setGaUserId, trackLead, trackLicensedCustomer } from "utils/analytics";
import { SECTION } from "utils/constants";
import { useIsLicensed, useWidget } from "utils/hooks";
import useIsBasicLicense from "utils/hooks/useBasicLicense";
import { storageKeys } from "utils/storage";
import {
  GrooveLinkType,
  setGrooveEmail,
  setGrooveEnterpriseApp,
  setGrooveFullServiceApp,
  setGrooveLicensedApp,
  setGrooveName,
  setGrooveSupportedApp,
  setGrooveSupportPlan,
  setGrooveUnlicensedApp,
  setGrooveUserCategory,
} from "utils/support";
import urls from "utils/urls";

import { PricingPackage } from "../Pricing/constants";

export const useInit = () => {
  const email = useAppSelector(selectEmail);
  const privateKey = useAppSelector(selectPrivateKey);
  const firstName = useAppSelector(selectFirstName);

  const isCartLoaded = useAppSelector(selectIsCartLoaded);
  const isFooterVisible = useAppSelector(selectIsFooterVisible);
  const dispatch = useAppDispatch();

  const isLicensed = useIsLicensed();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const licensedAppVisited = useWidget();
  const isBasicLicense = useIsBasicLicense();
  const router = useRouter();
  const license = useAppSelector(selectLicense);
  const overviewAppName = useAppSelector(selectAppName);

  const recentSupportPlanValue = useMemo(() => {
    if (!isLicensed) return undefined;
    else if (license.app.support === "none") return undefined;
    else if (license.app.support === "basic") return "Basic Support";
    else if (license.app.support === "premium") return "Premium Support";
    else if (license.app.support === "custom") return "Custom Support";
    else if (license.app.support === "enterprise") return "Enterprise Support";
    else if (license.app.support === "fullService")
      return "Full-Service Support";
    else return undefined;
  }, [isLicensed, license.app.support]);

  useCustomIcons();

  useEffect(() => {
    const localLastSavedTime = new Date().toISOString();
    dispatch(uiUpdated({ localLastSavedTime }));
  }, [dispatch]);

  const [recentLicensedApp = {}, setRecentLicensedApp] =
    useLocalStorage<GrooveLinkType>(storageKeys.app({ licensed: true }));
  const [recentUnlicensedApp = {}, setRecentUnlicensedApp] =
    useLocalStorage<GrooveLinkType>(storageKeys.app({ licensed: false }));

  const [userCategory = "", setUserCategory] = useLocalStorage<string>(
    storageKeys.userCategory()
  );

  const [recentSupportPlan = "", setRecentSupportPlan] =
    useLocalStorage<string>(storageKeys.supportPlan());

  const [recentSupportedApp = {}, setRecentSupportedApp] =
    useLocalStorage<GrooveLinkType>(storageKeys.supportedApp());

  const [recentEnterpriseApp = {}, setRecentEnterpriseApp] =
    useLocalStorage<GrooveLinkType>(storageKeys.enterpriseApp());

  const [recentFullServiceApp = {}, setRecentFullServiceApp] =
    useLocalStorage<GrooveLinkType>(storageKeys.fullserviceApp());

  // get userId for GA4 from local storage
  const [gaUserId, setLocalGaUserId] = useLocalStorage<string>(
    storageKeys.userIdGA4Analytics()
  );

  // set userId for GA4 to local storage (if doesn't exist) and send to GA4
  useEffect(() => {
    // if userId doesn't exist, generate a random one, set it to local storage and send to GA4
    if (!gaUserId?.startsWith("median_")) {
      const generatedUserId = `median_${Math.random()
        .toString(36)
        .substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;
      setLocalGaUserId(generatedUserId);
      setGaUserId(generatedUserId);
    }
    // if userId exists and starts with 'median_', set it to GA4
    else setGaUserId(gaUserId);
  }, [gaUserId, setLocalGaUserId]);

  const userCategoryValue: string | undefined = useMemo(() => {
    if (
      !recentSupportPlan &&
      !recentSupportedApp.link &&
      !recentEnterpriseApp.link &&
      !recentLicensedApp.link &&
      !recentUnlicensedApp.link
    )
      return "No apps found";
    else if (
      !recentSupportPlan &&
      !recentSupportedApp.link &&
      !recentEnterpriseApp.link &&
      !recentLicensedApp.link &&
      recentUnlicensedApp.link
    )
      return "Free user";
    else if (license.app.support === "enterprise" || recentEnterpriseApp.link)
      return "Enterprise support plan";
    else if (license.app.support === "fullService" || recentFullServiceApp.link)
      return "Full-Service support plan";
    else if (
      recentLicensedApp.link &&
      ["none", "appUpdates", "appUpdatesPremium", "appUpdatesCustom"].includes(
        license.app.support
      )
    )
      return "Paid without support";
    else if (["basic", "premium", "custom"].includes(license.app.support))
      return "Active support plan";
    else return undefined;
  }, [
    license.app.support,
    recentEnterpriseApp.link,
    recentFullServiceApp.link,
    recentLicensedApp.link,
    recentSupportPlan,
    recentSupportedApp.link,
    recentUnlicensedApp.link,
  ]);

  useEffect(() => {
    if (email && privateKey) {
      Sentry.setUser({ email, id: privateKey });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [email, privateKey]);

  useEffect(() => {
    if (isLicensed) {
      trackLicensedCustomer();
    }
  }, [isLicensed]);

  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
  }, []);

  // Set user category to LocalStorage
  useEffect(() => {
    setUserCategory(userCategoryValue);
    trackLead({
      customer_type: userCategoryValue,
      email,
    });
  }, [userCategoryValue, setUserCategory, email]);

  // Set user category to Groove
  useEffect(() => {
    setGrooveUserCategory(userCategory);
  }, [userCategory]);

  // Set recent support plan to LocalStorage
  useEffect(() => {
    setRecentSupportPlan(recentSupportPlanValue);
  }, [recentSupportPlanValue, setRecentSupportPlan]);

  // Set recent support plan to  Groove
  useEffect(() => {
    setGrooveSupportPlan(recentSupportPlan);
  }, [recentSupportPlan]);

  // Set recent supported app to LocalStorage
  useEffect(() => {
    if (
      privateKey &&
      isLicensed &&
      ["basic", "premium", "custom"].includes(license.app.support)
    ) {
      setRecentSupportedApp({
        link: urls.manage(privateKey),
        text: overviewAppName,
      });
    }
  }, [
    isLicensed,
    license.app.support,
    overviewAppName,
    privateKey,
    setRecentSupportedApp,
  ]);

  // Set recent supported app to Groove
  useEffect(() => {
    setGrooveSupportedApp(recentSupportedApp);
    trackLead({ recent_supported_app: recentSupportedApp.link, email });
  }, [email, recentSupportedApp]);

  // Set recent enterprise app to LocalStorage
  useEffect(() => {
    if (privateKey && isLicensed && license.app.support === "enterprise") {
      setRecentEnterpriseApp({
        link: urls.manage(privateKey),
        text: overviewAppName,
      });
    }
  }, [
    isLicensed,
    license.app.support,
    overviewAppName,
    privateKey,
    setRecentEnterpriseApp,
  ]);

  // Set recent fullService app to LocalStorage
  useEffect(() => {
    if (privateKey && isLicensed && license.app.support === "fullService") {
      setRecentFullServiceApp({
        link: urls.manage(privateKey),
        text: overviewAppName,
      });
    }
  }, [
    isLicensed,
    license.app.support,
    overviewAppName,
    privateKey,
    setRecentFullServiceApp,
  ]);

  //  Set recent enterprise app to Groove
  useEffect(() => {
    setGrooveEnterpriseApp(recentEnterpriseApp);
    trackLead({ recent_enterprise_app: recentEnterpriseApp.link, email });
  }, [email, recentEnterpriseApp]);

  //  Set recent fullservice app to Groove
  useEffect(() => {
    setGrooveFullServiceApp(recentFullServiceApp);
    trackLead({ recent_fullservice_app: recentFullServiceApp.link, email });
  }, [email, recentFullServiceApp]);

  useEffect(() => {
    if (privateKey) {
      if (isLicensed) {
        setRecentLicensedApp({
          link: urls.manage(privateKey),
          text: overviewAppName,
        });
      } else {
        setRecentUnlicensedApp({
          link: urls.manage(privateKey),
          text: overviewAppName,
        });
      }
    }
  }, [
    isLicensed,
    overviewAppName,
    privateKey,
    setRecentLicensedApp,
    setRecentUnlicensedApp,
  ]);

  useEffect(() => {
    if (!licensedAppVisited || !privateKey) return;
    setGrooveEmail(email);
    if (firstName) setGrooveName(firstName);

    if (isBasicLicense) {
      setGrooveLicensedApp(recentLicensedApp);
      trackLead({
        recent_licensed_app: recentLicensedApp.link,
        email,
      });
    } else if (!isLicensed) {
      setGrooveUnlicensedApp(recentUnlicensedApp);
      trackLead({
        recent_unlicensed_app: recentUnlicensedApp.link,
        email,
      });
    }
  }, [
    email,
    router,
    isLicensed,
    privateKey,
    recentLicensedApp,
    recentUnlicensedApp,
    setRecentLicensedApp,
    setRecentUnlicensedApp,
    licensedAppVisited,
    firstName,
    isBasicLicense,
  ]);

  const handleContactUsClick = useCallback(() => {
    dispatch(uiUpdated({ modalShown: "none" }));
  }, [dispatch]);

  // Contact us button
  useEffect(() => {
    const contactUsButton = document.getElementById("gn-contact-us");
    if (contactUsButton) {
      contactUsButton.addEventListener("click", handleContactUsClick);
    }
    return () => {
      if (contactUsButton) {
        contactUsButton.removeEventListener("click", handleContactUsClick);
      }
    };
  }, [handleContactUsClick, isFooterVisible]);

  const handleViewLicense = useCallback(() => {
    router.push(urls.appBuilder(privateKey, SECTION.license), undefined, {
      shallow: true,
    });
    dispatch(uiUpdated({ isRightPanelCollapsed: false, forceShowCart: true }));
  }, [dispatch, privateKey, router]);

  // Cart button appbuilder
  useEffect(() => {
    const appBuilderCartButton = document.querySelector(
      ".app-menu-cart-dark"
    ) as HTMLAnchorElement;
    const appBuilderCartButtonContainer = document.querySelector(
      ".cart-button-container-app"
    ) as HTMLAnchorElement;

    if (appBuilderCartButton) {
      if (isCartLoaded) {
        appBuilderCartButtonContainer.style.display = "flex";
        appBuilderCartButton.style.display = "inline-block";
        appBuilderCartButton.addEventListener("click", handleViewLicense);
      } else {
        appBuilderCartButtonContainer.style.display = "none";
        appBuilderCartButton.style.display = "none";
      }
    }

    if (appBuilderCartButtonContainer) {
      if (isAuthenticated)
        appBuilderCartButtonContainer.style.marginTop = "1rem";
      else appBuilderCartButtonContainer.style.marginTop = "0";
    }
    return () => {
      if (appBuilderCartButton) {
        appBuilderCartButton.removeEventListener("click", handleViewLicense);
      }
    };
  }, [handleViewLicense, isAuthenticated, isCartLoaded]);

  const handleViewCart = useCallback(() => {
    router.push(urls.pricing(PricingPackage.Cart));
  }, [router]);

  // Cart button pricing
  useEffect(() => {
    const pricingCartButton = document.querySelector(
      ".menu-cart"
    ) as HTMLAnchorElement;
    if (pricingCartButton) {
      if (isCartLoaded) {
        pricingCartButton.style.display = "inline-block";
        pricingCartButton.addEventListener("click", handleViewCart);
      } else {
        pricingCartButton.style.display = "none";
      }
    }
    return () => {
      if (pricingCartButton) {
        pricingCartButton.removeEventListener("click", handleViewCart);
      }
    };
  }, [handleViewCart, isCartLoaded]);
};

import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import * as EmailValidator from "email-validator";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import urlJoin from "url-join";
import * as yup from "yup";

import Button from "components/common/Button";
import Input, { RadioValue } from "components/common/Input";
import HookInput from "components/common/Input/HookInput";
import InputLabel from "components/common/Input/InputLabel";
import Modal from "components/common/Modal";
import api from "utils/api";
import { handleError } from "utils/errors";
import { useMobile, useRadioOptions } from "utils/hooks";
import urls from "utils/urls";

import IconBack from "./icons/back.svg";
import IconClose from "./icons/close.svg";
import styles from "./styles.module.scss";

interface Props {
  isOpen?: boolean;
  isPage?: boolean;
  defaultTopic?: ContactFormTopicType;
  onClose?: () => void;
}

export interface ContactFormModalInfo {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  phone: string;
  message: string;
}

export enum ContactFormTopicType {
  SelfService = "selfService",
  FullService = "fullService",
  Enterprise = "enterprise",
  PartnerProgram = "partnerProgram",
  CustomDevelopment = "customDevelopment",
  Publishing = "publishing",
  SolutionsArchitecture = "solutionsArchitecture",
  Security = "security",
  Other = "other",
}

export const ContactFormSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  message: yup.string().required(),
});

const phoneRegex = /^[+ ]?\d+(?: \d+)*$/;

export const ContactFormWithoutPhoneSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  message: yup.string().required(),
});

const ContactForm: React.FC<Props> = ({
  isOpen = false,
  isPage,
  defaultTopic,
  onClose,
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const router = useRouter();
  const currentPage = urlJoin(urls.base, router.asPath);

  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [topic, setTopic] = useState(
    defaultTopic || ContactFormTopicType.SelfService
  );

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (defaultTopic) {
      setTopic(defaultTopic);
    }
  }, [defaultTopic]);

  const { control, getValues, handleSubmit, setError, setValue, reset } =
    useForm<ContactFormModalInfo>({
      defaultValues: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        phone: "",
        message: "",
      },
      resolver: yupResolver(
        [
          ContactFormTopicType.FullService,
          ContactFormTopicType.Enterprise,
          ContactFormTopicType.PartnerProgram,
        ].includes(topic)
          ? ContactFormSchema
          : ContactFormWithoutPhoneSchema
      ),
    });

  const topicOptions: RadioValue[] = useRadioOptions(
    Object.values(ContactFormTopicType),
    "components.contactForm.topicOptions"
  );

  const handleSubmitInternal: SubmitHandler<ContactFormModalInfo> = async (
    data
  ) => {
    if (!EmailValidator.validate(data.email)) {
      setError("email", { type: "email" });
      return;
    }

    if (
      !phoneRegex.test(data.phone) &&
      [
        ContactFormTopicType.FullService,
        ContactFormTopicType.Enterprise,
        ContactFormTopicType.PartnerProgram,
      ].includes(topic)
    ) {
      setError("phone", { type: "phone" });
      return;
    }

    if (isLoading) return;
    setIsLoading(true);

    try {
      setIsSubmitted(true);
      await api.contact.contactUs({ ...data, topic, currentPage });

      if (onClose) {
        onClose();
      } else {
        toast(t("sections.services.contactUsSuccess"), { type: "success" });
        reset();
      }
    } catch (e) {
      handleError(e, { t });
    } finally {
      setIsLoading(false);
    }
  };

  if (isPage) {
    return (
      <div className={styles.contactFormContainer}>
        {isSubmitted ? (
          <div className={styles.formContainer}>
            <div className={classNames(styles.formContent, styles.success)}>
              {t("components.contactForm.success")}
            </div>
          </div>
        ) : (
          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(handleSubmitInternal)}
          >
            <div className={classNames(styles.formContent)}>
              <div className={styles.nameContainer}>
                <div className={styles.inputContainer}>
                  <HookInput
                    control={control}
                    label={t("common.firstName")}
                    className={styles.nameInput}
                    name="firstName"
                    required
                    onChange={(e) => {
                      setValue("firstName", e.value, {
                        shouldDirty: true,
                      });
                    }}
                    placeholder={t(
                      "containers.pricing.requestQuote.placeholder.firstName"
                    )}
                    type="text"
                    value={getValues("firstName")}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <HookInput
                    control={control}
                    label={t("common.lastName")}
                    className={styles.nameInput}
                    name="lastName"
                    onChange={(e) => {
                      setValue("lastName", e.value, {
                        shouldDirty: true,
                      });
                    }}
                    placeholder={t(
                      "containers.pricing.requestQuote.placeholder.lastName"
                    )}
                    type="text"
                    value={getValues("lastName")}
                  />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <InputLabel className={styles.label}>
                  {t("components.contactForm.topic")}
                </InputLabel>

                <Input
                  onChange={(e) => setTopic(e.value as ContactFormTopicType)}
                  itemContainerClassName={styles.radioInputContainer}
                  itemClassName={styles.radioButton}
                  options={topicOptions}
                  type="radio"
                  value={topic}
                  size="lg"
                />
              </div>
              <div className={styles.nameContainer}>
                <div className={styles.inputContainer}>
                  <HookInput
                    control={control}
                    label={t("common.company")}
                    className={styles.nameInput}
                    name="company"
                    onChange={(e) => {
                      setValue("company", e.value, {
                        shouldDirty: true,
                      });
                    }}
                    placeholder={t(
                      "containers.pricing.requestQuote.placeholder.companyName"
                    )}
                    type="text"
                    value={getValues("company")}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <HookInput
                    control={control}
                    label={t("common.email")}
                    className={styles.nameInput}
                    name="email"
                    onChange={(e) => {
                      setValue("email", e.value, {
                        shouldDirty: true,
                      });
                    }}
                    placeholder={t(
                      "containers.pricing.requestQuote.placeholder.email"
                    )}
                    type="email"
                    value={getValues("email")}
                  />
                </div>
              </div>
              {[
                ContactFormTopicType.FullService,
                ContactFormTopicType.Enterprise,
                ContactFormTopicType.PartnerProgram,
              ].includes(topic) && (
                <div className={styles.nameContainer}>
                  <div className={styles.inputContainer}>
                    <HookInput
                      control={control}
                      label={t("common.phone")}
                      className={styles.nameInput}
                      name="phone"
                      onChange={(e) => {
                        setValue("phone", e.value, {
                          shouldDirty: true,
                        });
                      }}
                      placeholder={t(
                        "containers.pricing.requestQuote.placeholder.phone"
                      )}
                      type="text"
                      value={getValues("phone")}
                    />
                  </div>
                  <div className={styles.inputContainer} />
                </div>
              )}

              <div className={styles.inputContainer}>
                <HookInput
                  label={t("components.contactForm.message")}
                  control={control}
                  name="message"
                  onChange={(e) => {
                    setValue("message", e.value, {
                      shouldDirty: true,
                    });
                  }}
                  placeholder={t(
                    "containers.pricing.requestQuote.placeholder.message"
                  )}
                  type="textarea"
                  value={getValues("message")}
                  maxLength={2000}
                  rows={6}
                />
              </div>
            </div>

            <div className={styles.footerContainer}>
              <footer className={classNames(styles.footer, styles.page)}>
                <ReCAPTCHA
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""}
                  ref={recaptchaRef}
                  onChange={(e: string | null) => setIsVerified(!!e)}
                />
                <div className={styles.buttons}>
                  <Button
                    className={styles.modalFooterButton}
                    isSubmit
                    size="lg"
                    type="primary"
                    disabled={isLoading || !isVerified}
                    loading={isLoading}
                    loaderColor="#ffffff"
                  >
                    {t("common.submit")}
                  </Button>
                </div>
              </footer>
            </div>
          </form>
        )}
      </div>
    );
  }

  return (
    <Modal
      className={classNames(styles.modalContainer, styles.requestQuoteModal)}
      overlayClassName={styles.contactFormOverlay}
      headerClassName={styles.none}
      isOpen={!!isOpen}
      onRequestClose={onClose}
    >
      <header className={styles.header}>
        {isMobile && (
          <Button className={classNames(styles.back)} onClick={onClose}>
            <IconBack />

            {t("common.back")}
          </Button>
        )}

        {!isMobile && (
          <>
            <div className={styles.headerButton} />

            <h2 className={styles.headerTitle}>
              {t("components.contactForm.title")}
            </h2>

            <Button className={styles.headerButton} onClick={onClose}>
              <IconClose />
            </Button>
          </>
        )}
      </header>
      <form
        className={classNames(styles.formContainer, styles.modal)}
        onSubmit={handleSubmit(handleSubmitInternal)}
      >
        <div className={classNames(styles.formContent, styles.modal)}>
          <div className={styles.nameContainer}>
            <div className={styles.inputContainer}>
              <HookInput
                control={control}
                label={t("common.firstName")}
                className={styles.nameInput}
                name="firstName"
                required
                onChange={(e) => {
                  setValue("firstName", e.value, {
                    shouldDirty: true,
                  });
                }}
                placeholder={t(
                  "containers.pricing.requestQuote.placeholder.firstName"
                )}
                type="text"
                value={getValues("firstName")}
              />
            </div>
            <div className={styles.inputContainer}>
              <HookInput
                control={control}
                label={t("common.lastName")}
                className={styles.nameInput}
                name="lastName"
                onChange={(e) => {
                  setValue("lastName", e.value, {
                    shouldDirty: true,
                  });
                }}
                placeholder={t(
                  "containers.pricing.requestQuote.placeholder.lastName"
                )}
                type="text"
                value={getValues("lastName")}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.label}>
              {t("components.contactForm.topic")}
            </InputLabel>

            <Input
              onChange={(e) => setTopic(e.value as ContactFormTopicType)}
              itemContainerClassName={styles.radioInputContainer}
              itemClassName={styles.radioButton}
              options={topicOptions}
              type="radio"
              value={topic}
              size="lg"
            />
          </div>
          <div className={styles.nameContainer}>
            <div className={styles.inputContainer}>
              <HookInput
                control={control}
                label={t("common.email")}
                className={styles.nameInput}
                name="email"
                onChange={(e) => {
                  setValue("email", e.value, {
                    shouldDirty: true,
                  });
                }}
                placeholder={t(
                  "containers.pricing.requestQuote.placeholder.email"
                )}
                type="email"
                value={getValues("email")}
              />
            </div>
            <div className={styles.inputContainer}>
              <HookInput
                control={control}
                label={t("common.company")}
                className={styles.nameInput}
                name="company"
                onChange={(e) => {
                  setValue("company", e.value, {
                    shouldDirty: true,
                  });
                }}
                placeholder={t(
                  "containers.pricing.requestQuote.placeholder.companyName"
                )}
                type="text"
                value={getValues("company")}
              />
            </div>
          </div>
          {[
            ContactFormTopicType.FullService,
            ContactFormTopicType.Enterprise,
            ContactFormTopicType.PartnerProgram,
          ].includes(topic) && (
            <div className={styles.nameContainer}>
              <div className={styles.inputContainer}>
                <HookInput
                  control={control}
                  label={t("common.phone")}
                  className={styles.nameInput}
                  name="phone"
                  onChange={(e) => {
                    setValue("phone", e.value, {
                      shouldDirty: true,
                    });
                  }}
                  placeholder={t(
                    "containers.pricing.requestQuote.placeholder.phone"
                  )}
                  type="text"
                  value={getValues("phone")}
                />
              </div>
              <div className={styles.inputContainer} />
            </div>
          )}

          <div className={styles.inputContainer}>
            <HookInput
              label={t("components.contactForm.message")}
              control={control}
              name="message"
              onChange={(e) => {
                setValue("message", e.value, {
                  shouldDirty: true,
                });
              }}
              placeholder={t(
                "containers.pricing.requestQuote.placeholder.message"
              )}
              type="textarea"
              value={getValues("message")}
              maxLength={2000}
              rows={5}
            />
          </div>
        </div>

        <div className={styles.footerContainer}>
          <footer
            className={classNames(styles.footer, styles.requestQuoteModal)}
          >
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""}
              ref={recaptchaRef}
              onChange={(e: string | null) => setIsVerified(!!e)}
            />
            <div className={styles.buttons}>
              <Button
                className={styles.modalFooterButton}
                onClick={onClose}
                disabled={isLoading}
                size="lg"
                type="light"
              >
                {t("common.cancel")}
              </Button>
              <Button
                className={styles.modalFooterButton}
                isSubmit
                size="lg"
                type="primary"
                disabled={isLoading || !isVerified}
                loading={isLoading}
                loaderColor="#ffffff"
              >
                {t("common.submit")}
              </Button>
            </div>
          </footer>
        </div>
      </form>
    </Modal>
  );
};

export default ContactForm;

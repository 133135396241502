/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconCheckboxCheck from "assets/images/icons/IconCheckboxCheck";
import IconCheckboxSelected from "assets/images/icons/IconCheckboxSelected";
import IconCheckboxUncheck from "assets/images/icons/IconCheckboxUncheck";
import Button, { ButtonSize } from "components/common/Button";
import PopConfirm from "components/common/PopConfirm";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface CheckBoxValue {
  cancelText?: string;
  confirm?: boolean;
  confirmText?: string;
  confirmTitle?: React.ReactNode;
  label: React.ReactNode;
  value: string;
  icon?: React.ReactNode;
}

export interface CheckBoxInputProps {
  allowBlank?: boolean;
  allowOnPreviewMode?: boolean;
  full?: boolean;
  itemClassName?: string;
  itemContainerClassName?: string;
  itemStyles?: React.CSSProperties[];
  onChange?: (event: InputChangeEvent<string | null>) => void;
  options: CheckBoxValue[];
  type: "checkbox";
  value?: string[];
  vertical?: boolean;
  horizontal?: boolean;
  size?: ButtonSize;
  disabledItems?: { value: string; enabled: boolean }[];
  onSelectAll?: () => void;
}

type Props = BaseProps & CheckBoxInputProps;

const CheckBoxInput: React.FC<Props> = ({
  allowBlank,
  allowOnPreviewMode,
  className,
  disabled,
  disabledItems = [],
  id,
  itemClassName,
  itemContainerClassName,
  inline,
  label,
  labelClassName,
  labelSize,
  name,
  onChange = () => null,
  options,
  value = [],
  vertical,
  horizontal,
  size,
  full,
  itemStyles = [],
  onSelectAll,
}) => {
  const handleChange = (option: CheckBoxValue) => {
    if (allowBlank && value.length === 1 && value[0] === option.value) {
      onChange({ id, name, value: null });
    } else {
      onChange({ id, name, value: option.value });
    }
  };

  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        htmlFor={id}
        label
        size={labelSize}
      >
        {label}
      </InputLabel>

      {onSelectAll && (
        <Button
          allowOnPreviewMode={allowOnPreviewMode}
          className={classNames(
            styles.radioItem,
            {
              [styles.lg]: size === "lg",
              [styles.md]: size === "md",
              [styles.allowBlank]: allowBlank,
              [styles.vertical]: vertical,
              [styles.horizontal]: horizontal,
              [styles.full]: full,
            },
            itemClassName
          )}
          // style={itemStyles[index]}
          onClick={onSelectAll}
          disabled={disabled}
        >
          {value.length === options.length - disabledItems.length ? (
            <IconCheckboxCheck />
          ) : value.length > 0 ? (
            <IconCheckboxSelected />
          ) : (
            <IconCheckboxUncheck />
          )}

          <span>{t("common.selectAll")}</span>
        </Button>
      )}

      <div
        className={classNames(
          styles.checkboxContainer,
          vertical && styles.vertical,
          horizontal && styles.horizontal,
          full && styles.full,
          itemContainerClassName
        )}
      >
        {options.map((option, index) => (
          <PopConfirm
            cancelText={option.cancelText}
            confirmText={option.confirmText}
            disabled={!option.confirm}
            key={option.value}
            onConfirm={() => handleChange(option)}
            title={option.confirmTitle}
          >
            <Button
              allowOnPreviewMode={allowOnPreviewMode}
              className={classNames(
                styles.radioItem,
                {
                  [styles.lg]: size === "lg",
                  [styles.md]: size === "md",
                  [styles.allowBlank]: allowBlank,
                  [styles.vertical]: vertical,
                  [styles.horizontal]: horizontal,
                  [styles.full]: full,
                },
                itemClassName
              )}
              style={itemStyles[index]}
              onClick={option.confirm ? undefined : () => handleChange(option)}
              disabled={
                disabled || disabledItems.some((i) => i.value === option.value)
              }
            >
              {value.includes(option.value) ||
              disabledItems.find((i) => i.value === option.value)?.enabled ? (
                <IconCheckboxCheck />
              ) : (
                <IconCheckboxUncheck />
              )}
              {option.icon}
              <span>{option.label}</span>
            </Button>
          </PopConfirm>
        ))}
      </div>
    </div>
  );
};

export default CheckBoxInput;

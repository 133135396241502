import { useMemo } from "react";

import { getSignedUrl } from "utils/s3";
import { toRelativeUrl } from "utils/urls";

const useImage = (src?: string | null) => {
  const value = useMemo(() => {
    if (src) {
      return getSignedUrl(toRelativeUrl(src));
    }
    return src;
  }, [src]);

  return value;
};

export default useImage;

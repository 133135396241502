import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

import { useAppSelector } from "stores";
import { selectPrivateKey } from "stores/features/project";
import urls from "utils/urls";

const useSocketIo = () => {
  const privateKey = useAppSelector(selectPrivateKey);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connectionError, setConnectionError] = useState<string | null>(null);

  useEffect(() => {
    if (!privateKey) return;
    const newSocket = io(urls.base || "https://median.co/", {
      path: "/socket",
      transports: ["websocket", "polling"],
    });

    const connectionTimeout = setTimeout(() => {
      if (!newSocket.connected) {
        newSocket.disconnect();
        setConnectionError("Connection timeout. Please try again later.");
      }
    }, 10000); // 10 seconds timeout

    newSocket.on("connect", () => {
      clearTimeout(connectionTimeout); // Clear the timeout if connected
    });

    newSocket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
      setConnectionError("Socket connection error. Please try again later.");
      newSocket.disconnect(); // Disconnect on error
    });

    newSocket.on("connect_timeout", () => {
      console.error("Socket connection timeout");
      setConnectionError("Connection timeout. Please try again later.");
      newSocket.disconnect(); // Disconnect on timeout
    });

    setSocket(newSocket);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(connectionTimeout); // Clear timeout on cleanup
      newSocket.disconnect();
    };
  }, [privateKey]);

  return { socket, connectionError };
};

export default useSocketIo;

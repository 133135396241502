/* eslint-disable @typescript-eslint/no-explicit-any */

import { uiUpdated } from "stores/features/ui";
import { Icon } from "types/Icon";

import apiSlice from ".";
import { TagType } from "./constants";
import { camelizedResponseJson } from "./utils";

export interface APIGetIcons {
  resIconsSearchTermsJson: {
    iconsSearchTerms: Icon[];
  };
}

export interface APIGetCustomIcons {
  customIcons: Icon[];
  iconCodeMap: { [key: string]: string };
}

const generalApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFontAwesomeIcons: build.query<Icon[], void>({
      providesTags: [TagType.Icon],
      query: () => ({
        url: "getIconsJson",
        responseHandler: (response) => camelizedResponseJson(response),
      }),
      transformResponse: (response: APIGetIcons) =>
        response.resIconsSearchTermsJson.iconsSearchTerms,
    }),

    getMaterialIcons: build.query<Icon[], void>({
      providesTags: [TagType.Icon],
      query: () => ({
        url: "getMaterialIconsJson",
        responseHandler: (response) => camelizedResponseJson(response),
      }),
      transformResponse: (response: APIGetIcons) =>
        response.resIconsSearchTermsJson.iconsSearchTerms,
    }),

    getCustomIcons: build.query<APIGetCustomIcons, string | undefined>({
      providesTags: [TagType.Icon],
      query: (url) => ({
        url: "getCustomIconsCss",
        params: { url },
        responseHandler: async (response) => {
          try {
            const json = await response.json();

            const regex = /([\w-]+):before \{ content: '\\([\w-]+)'; \}/g;
            const matches = [...json.matchAll(regex)];

            const customIcons: Icon[] = [];
            const iconCodeMap: { [key: string]: string } = {};

            matches.forEach(([, name, code]) => {
              customIcons.push({
                code: `custom ${name}`,
                terms: [name.slice(5).split("-").join(" ")],
              });
              iconCodeMap[`custom ${name}`] = code;
            });
            return { customIcons, iconCodeMap };
          } catch {
            return { customIcons: [], iconCodeMap: {} };
          }
        },
      }),
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(uiUpdated({ customIconsData: data }));
        } catch {
          // NO-OP
        }
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCustomIconsQuery,
  useGetFontAwesomeIconsQuery,
  useGetMaterialIconsQuery,
} = generalApi;

export default generalApi;

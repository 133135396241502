export const validAudioTypes = [
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",
  "audio/aac",
  "audio/mp4",
  "audio/x-m4a",
  "audio/flac",
  "audio/x-flac",
  "audio/webm",
  "audio/x-caf",
  "audio/3gpp",
  "audio/3gpp2",
  ".mp3",
  ".wav",
  ".ogg",
  ".aac",
  ".m4a",
  ".flac",
  ".webm",
  ".caf",
];

import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export type NotificationType =
  | "weak"
  | "fair"
  | "good"
  | "strong"
  | "secure"
  | "error"
  | "warning"
  | "success"
  | "info"
  | "announcement"
  | "default";

interface Props {
  borderless?: boolean;
  className?: string;
  type: NotificationType;
  isBanner?: boolean;
}

const NotificationContainer: React.FC<Props> = ({
  borderless = true,
  children,
  className,
  isBanner,
  type,
}) => (
  <div
    className={classNames(
      isBanner ? styles.bannerContainer : styles.notificationContainer,
      {
        [styles.red]: ["weak", "error"].includes(type),
        [styles.yellow]: ["fair", "warning"].includes(type),
        [styles.orange]: ["good"].includes(type),
        [styles.darkBlue]: ["strong"].includes(type),
        [styles.green]: ["secure", "success"].includes(type),
        [styles.blue]: ["info"].includes(type),
        [styles.gray]: ["default"].includes(type),
        [styles.announcement]: ["announcement"].includes(type),
        [styles.borderless]: borderless,
      },
      className
    )}
  >
    {children}
  </div>
);

export default NotificationContainer;

import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";

import IconClose from "assets/images/icons/close.svg";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

ReactModal.setAppElement("#portal");

export interface ModalProps extends ReactModal.Props {
  footer?: React.ReactNode;
  footerClassName?: string;
  fullscreen?: boolean;
  headerClassName?: string;
  headerTitleClassName?: string;
  closeButtonClassName?: string;
  onClose?: () => void;
  onClick?: () => void;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  className,
  children,
  footer,
  footerClassName,
  fullscreen,
  headerClassName,
  headerTitleClassName,
  closeButtonClassName,
  onClose,
  subtitle,
  title,
  overlayClassName,
  ...otherProps
}) => (
  <ReactModal
    className={classNames(
      styles.content,
      fullscreen && styles.fullscreen,
      className
    )}
    overlayClassName={classNames(styles.overlay, overlayClassName)}
    {...otherProps}
  >
    <header className={classNames(styles.header, headerClassName)}>
      <div className={styles.headerTitleContainer}>
        <h2 className={classNames(styles.headerTitle, headerTitleClassName)}>
          {title}
        </h2>

        {onClose && (
          <Button
            className={closeButtonClassName}
            onClick={onClose}
            allowOnPreviewMode
          >
            <IconClose />
          </Button>
        )}
      </div>
      {subtitle && <span className={styles.headerSubtitle}>{subtitle}</span>}
    </header>

    {children}

    {footer && (
      <footer className={classNames(styles.footer, footerClassName)}>
        {footer}
      </footer>
    )}
  </ReactModal>
);

export default Modal;

import classNames from "classnames";
import Decimal from "decimal.js";
import React, { forwardRef, useCallback, useEffect, useState } from "react";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface NumberInputProps {
  contentClassName?: string;
  max?: number;
  min?: number;
  onChange?: (event: InputChangeEvent<number | undefined>) => void;
  percentage?: boolean;
  placeholder?: string;
  step?: number;
  type: "number";
  unit?: string;
  value?: number | null;
}

type Props = BaseProps & NumberInputProps;

const NumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoFocus,
      className,
      contentClassName,
      disabled,
      id,
      inline,
      label,
      labelClassName,
      labelSize,
      max,
      min,
      name,
      onBlur = () => null,
      onChange = () => null,
      onFocus = () => null,
      percentage,
      placeholder,
      readOnly,
      required,
      step = 1,
      unit = "",
      value,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [internalValue, setInternalValue] = useState(`${value ?? ""}`);

    useEffect(() => {
      if (!isFocused) {
        setInternalValue((prevState) => {
          if (!prevState) {
            return prevState;
          }

          if (value == null) {
            return "";
          }

          const nextValue = percentage
            ? new Decimal(value).mul(100).toNumber()
            : value;
          return `${nextValue}${unit}`;
        });
      }
    }, [isFocused, percentage, unit, value]);

    const handleBlur = useCallback(() => {
      setIsFocused(false);
      onBlur(id);
      if (value == null) return;

      if (max != null && value > max) {
        onChange({ id, name, value: max });
      }

      if (min != null && value < min) {
        onChange({ id, name, value: min });
      }
    }, [id, max, min, name, onBlur, onChange, value]);

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus(id, e);
      },
      [id, onFocus]
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event.target.value);
        const valueParsed = event.target.value.replace(/[^-\d.]/g, "");

        if (valueParsed === "") {
          onChange({ id, name, value: undefined });
        } else {
          let nextValue = Number.parseFloat(valueParsed) || 0;
          if (percentage) {
            nextValue = new Decimal(Number.parseFloat(valueParsed) || 0)
              .div(100)
              .toNumber();
          }

          if (max != null && nextValue > max) {
            nextValue = max;
          }

          if (min != null && nextValue < min) {
            nextValue = min;
          }

          onChange({ id, name, value: nextValue });
        }
      },
      [id, max, min, name, onChange, percentage]
    );

    const handleStepUp = useCallback(() => {
      let nextValue = min || max || step;

      if (value != null) {
        nextValue = new Decimal(value).add(new Decimal(step)).toNumber();
      }

      if (max != null && nextValue > max) {
        nextValue = max;
      }

      if (min != null && nextValue < min) {
        nextValue = min;
      }

      setInternalValue(`${nextValue}${unit}`);
      onChange({ id, name, value: nextValue });
    }, [id, max, min, name, onChange, step, unit, value]);

    const handleStepDown = useCallback(() => {
      let nextValue = max || min || step;

      if (value != null) {
        nextValue = new Decimal(value).minus(new Decimal(step)).toNumber();
      }

      if (max != null && nextValue > max) {
        nextValue = max;
      }

      if (min != null && nextValue < min) {
        nextValue = min;
      }

      setInternalValue(`${nextValue}${unit}`);
      onChange({ id, name, value: nextValue });
    }, [id, max, min, name, onChange, step, unit, value]);

    return (
      <label
        className={classNames(
          styles.container,
          { [styles.inline]: inline },
          className
        )}
        htmlFor={id}
      >
        <InputLabel
          className={classNames(inline && styles.labelInline, labelClassName)}
          size={labelSize}
        >
          {label}
        </InputLabel>

        <div
          className={classNames(styles.numberInputContainer, contentClassName)}
        >
          <input
            autoFocus={autoFocus}
            className={classNames(styles.numberInput, {
              [styles.percentage]: percentage,
            })}
            disabled={disabled}
            id={id}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            required={required}
            type="text"
            value={internalValue}
          />

          <div
            className={classNames(styles.numberInputStepContainer, {
              [styles.focused]: isFocused,
            })}
          >
            <button
              className={styles.numberInputStep}
              onClick={handleStepUp}
              type="button"
              disabled={disabled || readOnly}
            >
              +
            </button>
            <button
              className={styles.numberInputStep}
              onClick={handleStepDown}
              type="button"
              disabled={disabled || readOnly}
            >
              -
            </button>
          </div>
        </div>
      </label>
    );
  }
);

export default NumberInput;

import { camelize } from "humps";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { ORGSECTION } from "utils/constants";

const useOrgSection = () => {
  const router = useRouter();
  const { query } = router;

  return useMemo(() => {
    let sections = (query.sections as string[] | undefined) || [];
    if (query.orgId) {
      sections = sections.map((section) => camelize(section));
      if (!Object.values(ORGSECTION).includes(sections[0] as ORGSECTION)) {
        sections[0] = ORGSECTION.apps;
      }
    }

    return sections as [ORGSECTION, ...string[]];
  }, [query]);
};

export default useOrgSection;

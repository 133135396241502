/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

import classNames from "classnames";
import React, { useCallback, useRef, useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import { Color, ColorPicker, toColor } from "components/common/ColorPicker";
import { useAppDispatch, useAppSelector } from "stores";
import { selectLastUsedColors, uiUpdated } from "stores/features/ui";
import { useClickOutside, useMobile } from "utils/hooks";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface ColorInputProps {
  alpha?: boolean;
  onChange?: (event: InputChangeEvent<Color>) => void;
  type: "color";
  value: string;
}

type Props = BaseProps & ColorInputProps;

const ColorInput: React.FC<Props> = ({
  alpha,
  className,
  dark,
  disabled,
  id,
  inline,
  label,
  labelClassName,
  labelSize = "lg",
  name,
  onChange = () => null,
  value,
}) => {
  const lastUsedColors = useAppSelector(selectLastUsedColors);
  const dispatch = useAppDispatch();

  const actualValue = value || "#ffffff";
  const containerRef = useRef<HTMLDivElement>(null);
  const [internalValue, setInternalValue] = useState<Color>(
    toColor("hex", "#ffffff")
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const isMobile = useMobile();

  const handleChange = useCallback(
    (nextValue: Color) => {
      setInternalValue(nextValue);
      onChange({ id, name, value: nextValue });
      setHasChanges(true);
    },
    [id, name, onChange]
  );

  const handleClickOutside = useCallback(() => {
    setIsPopupVisible(false);

    if (!lastUsedColors.includes(internalValue.hex) && hasChanges) {
      const newLastUsedColors = [...lastUsedColors, internalValue.hex];
      dispatch(
        uiUpdated({
          lastUsedColors: newLastUsedColors.slice(-9),
        })
      );
    }
  }, [dispatch, hasChanges, internalValue, lastUsedColors]);

  useClickOutside({
    onClickOutside: handleClickOutside,
    ref: containerRef,
  });

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      closeOnTriggerHidden: true,
      interactive: true,
      offset: isMobile ? undefined : [0, -170],
      placement: isMobile ? "auto" : "right-end",
      trigger: "click",
      visible: isPopupVisible,
    });

  const handleFocus = useCallback(() => {
    if (!disabled && !isPopupVisible) {
      setIsPopupVisible(true);
      setHasChanges(false);
      setInternalValue(toColor("hex", actualValue));
    }
  }, [actualValue, disabled, isPopupVisible]);

  return (
    <label
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
      htmlFor={id}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        size={labelSize}
      >
        {label}
      </InputLabel>

      <div className={styles.colorInputContainer}>
        <div
          className={classNames(styles.colorInputPreview, {
            [styles.dark]: dark,
          })}
          onClick={handleFocus}
          style={{
            backgroundColor: actualValue,
          }}
        />

        <input
          className={classNames(styles.textInputColor, {
            [styles.dark]: dark,
            [styles.focused]: isPopupVisible,
          })}
          onFocus={handleFocus}
          readOnly
          ref={setTriggerRef}
          value={
            actualValue === "transparent" ||
            (alpha && internalValue.rgb.a === 0)
              ? "transparent"
              : actualValue.toUpperCase()
          }
          disabled={disabled}
        />
      </div>

      {isPopupVisible && (
        <div
          id={id}
          ref={setTooltipRef}
          {...getTooltipProps({
            className: classNames("tooltip-container", styles.colorPopup),
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />

          <div ref={containerRef}>
            <ColorPicker
              alpha={alpha}
              color={internalValue}
              height={200}
              hideHSV
              onChange={handleChange}
              onChangeComplete={handleChange}
              suggestedColors={lastUsedColors}
              width={alpha ? 330 : 300}
            />
          </div>
        </div>
      )}
    </label>
  );
};

export default ColorInput;

import { baseAxios } from "./utils";

export interface UpdateHubspotChargeSucceededInformationArgs {
  firstname: string;
  lastname: string;
  company: string;
  amount?: number;
  email: string;
  phone: string;
  description?: string;
}

export const updateChargeSucceededInformation = async (
  args: UpdateHubspotChargeSucceededInformationArgs
) => {
  const { data } = await baseAxios.post("/quote-request", args);
  return data;
};

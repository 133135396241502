import axios, { AxiosRequestConfig } from "axios";

import { MenuItemValue } from "components/common/MenuBuilder/constants";
import { AppBuilder, AppConfig } from "utils/appBuilder";
import urls from "utils/urls";

import { baseAxios } from "./utils";

export interface APIConvertAppConfig {
  appBuilder: AppBuilder;
  appConfig: AppConfig;
}

export const convertAppConfig = async (
  appBuilder: AppBuilder,
  publicKey: string
) => {
  const response = await baseAxios.post<APIConvertAppConfig>(
    "utilities/convert/appConfig",
    { appBuilder, publicKey }
  );
  return response.data;
};

interface APICrawlWebsite {
  internalHosts: string[];
  navOptions: {
    navLinks: MenuItemValue[];
  }[];
  title: string;
}

export const crawlWebsite = async (url: string) => {
  const response = await baseAxios.get<APICrawlWebsite[]>("crawl", {
    params: { url },
  });
  return response.data;
};

interface APICrawlWebsite {
  icon: string | null;
}

export const crawlIcon = async (url: string) => {
  const response = await baseAxios.get<APICrawlWebsite[]>("crawlIcon", {
    params: { url },
  });
  return response.data;
};

interface APIUploadArgs {
  config?: AxiosRequestConfig<FormData>;
  extension?: string;
  file: File;
  publicKey: string;
  tempDir?: boolean;
}

interface APIUploadCredentials {
  url: string;
  key: string;
  s3Policy: string;
  s3Signature: string;
  AWSAccessKeyId: string;
}

// interface APIUploadResponse {
//   declaration: {
//     attributes: {
//       version: string;
//       encoding: string;
//     };
//   };
//   elements: [
//     {
//       type: string;
//       name: string;
//       elements: {
//         type: string;
//         name: string;
//         elements: {
//           type: string;
//           text: string;
//         }[];
//       }[];
//     }
//   ];
// }

export const upload = async (args: APIUploadArgs) => {
  const { config, extension, file, publicKey, tempDir } = args;

  const filename = file.name.split(".")[0];

  let fileType = file.type;
  if (!fileType && file.name.endsWith(".caf")) {
    fileType = "audio/x-caf";
  }

  const { data: credentials } = await axios.get<APIUploadCredentials>(
    "s3credential",
    {
      baseURL: urls.api,
      params: { filename, extension, publicKey, tempDir, type: fileType },
    }
  );

  const formData = new FormData();
  formData.append("key", credentials.key);
  formData.append("AWSAccessKeyId", credentials.AWSAccessKeyId);
  formData.append("success_action_status", "201");
  formData.append("Policy", credentials.s3Policy);
  formData.append("Signature", credentials.s3Signature);
  formData.append("Content-Type", fileType);
  formData.append("file", file);

  await axios.post(credentials.url, formData, config);
  const location = `https://s3.amazonaws.com/${process.env.NEXT_PUBLIC_S3_BUCKET}/${credentials.key}`;

  return location;
};

export const uploadKeystore = async (
  privateKey: string,
  keystore: File,
  alias: string,
  password: string
) => {
  const formData = new FormData();
  formData.append("keystore", keystore);
  formData.append("alias", alias);
  formData.append("password", password);
  const response = await axios.post(urls.uploadKeystore(privateKey), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

import React, { SVGProps } from "react";

const IconCheckboxSelected: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3ZM8.25 11C7.55964 11 7 11.5596 7 12.25C7 12.9404 7.55964 13.5 8.25 13.5H16.75C17.4404 13.5 18 12.9404 18 12.25C18 11.5596 17.4404 11 16.75 11H8.25Z"
      fill="#1A100B"
    />
    <path
      d="M18 2.5H6V3.5H18V2.5ZM21.5 6C21.5 4.06701 19.933 2.5 18 2.5V3.5C19.3808 3.5 20.5 4.61929 20.5 6H21.5ZM21.5 18V6H20.5V18H21.5ZM18 21.5C19.933 21.5 21.5 19.933 21.5 18H20.5C20.5 19.3808 19.3808 20.5 18 20.5V21.5ZM6 21.5H18V20.5H6V21.5ZM2.5 18C2.5 19.933 4.06701 21.5 6 21.5V20.5C4.61929 20.5 3.5 19.3808 3.5 18H2.5ZM2.5 6V18H3.5V6H2.5ZM6 2.5C4.06701 2.5 2.5 4.06701 2.5 6H3.5C3.5 4.61929 4.61929 3.5 6 3.5V2.5ZM7.5 12.25C7.5 11.8358 7.83579 11.5 8.25 11.5V10.5C7.2835 10.5 6.5 11.2835 6.5 12.25H7.5ZM8.25 13C7.83579 13 7.5 12.6642 7.5 12.25H6.5C6.5 13.2165 7.2835 14 8.25 14V13ZM16.75 13H8.25V14H16.75V13ZM17.5 12.25C17.5 12.6642 17.1642 13 16.75 13V14C17.7165 14 18.5 13.2165 18.5 12.25H17.5ZM16.75 11.5C17.1642 11.5 17.5 11.8358 17.5 12.25H18.5C18.5 11.2835 17.7165 10.5 16.75 10.5V11.5ZM8.25 11.5H16.75V10.5H8.25V11.5Z"
      fill="#1A100B"
    />
  </svg>
);

export default IconCheckboxSelected;

import { hubspotUpdated } from "stores/features/hubspot";
import { organizationMemberDetailsUpdated } from "stores/features/organization";
import { UserState, userUpdated } from "stores/features/user";

import apiSlice from ".";

interface ApiUserArgs {
  headers: Record<string, string>;
}

interface ApiRegisterArgs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  token: string;
  hasAcceptedTosAndPrivacyPolicy?: boolean;
  redirectUrl?: string;
}

interface ApiSigninArgs {
  email: string;
  password: string;
}

interface ApiVerifyEmailArgs {
  email: string;
  redirectUrl?: string;
  type: "signup" | "resetPassword";
  addAppId?: string;
}

interface ApiVerifyTokenArgs {
  token: string;
  redirectUrl?: string;
  type: "signup" | "resetPassword";
}

interface ApiVerifyToken {
  email?: string;
  redirectUrl?: string;
}

interface ApiSignin {
  redirectUrl?: string;
  hubspotConfig: {
    identificationEmail: string;
    identificationToken: string;
  };
  currentOrganizationId: string;
}

interface ApiResetPasswordArgs {
  email: string;
  password: string;
  token: string;
  redirectUrl?: string;
}

interface ApiChangePasswordArgs {
  currentPassword: string;
  newPassword: string;
}

interface ApiUpdateUserArgs {
  userId: string;
  firstName: string;
  lastName: string;
}

interface ApiUpdateUser {
  firstName: string;
  lastName: string;
}

interface ApiChangePassword {
  error: string;
}

const userApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<UserState, ApiUserArgs | void>({
      query: (args) => ({
        url: "account/user/details",
        headers: args ? args.headers : undefined,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          const currentOrganization = data.organizations.find(
            (i) => i.isCurrentOrganization
          );

          dispatch(
            userUpdated({
              ...data,
              otherOrganization: {
                isOtherOrganization: false,
                orgId: currentOrganization?.id,
              },
            })
          );
        } catch (e) {}
      },
      extraOptions: {
        maxRetries: 1,
      },
    }),

    register: build.mutation<ApiSignin, ApiRegisterArgs>({
      query: (args) => ({
        url: "account/register",
        method: "POST",
        body: args,
      }),
    }),

    signin: build.mutation<ApiSignin, ApiSigninArgs>({
      query: (args) => ({
        url: "account/user/login",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(hubspotUpdated({ ...data.hubspotConfig }));
        } catch (e) {
          // NO-OP
        }
      },
    }),

    signout: build.mutation<void, void>({
      query: () => ({
        url: "account/user/logout",
        method: "POST",
        responseHandler: (response) => response.text(),
      }),
    }),

    deleteAccount: build.mutation<void, void>({
      query: () => ({
        url: "account/user/delete",
        method: "POST",
        responseHandler: (response) => {
          if (response.ok) {
            return response.text();
          }
          return response.json();
        },
      }),
    }),

    verifyEmail: build.mutation<void, ApiVerifyEmailArgs>({
      query: ({ email, redirectUrl, type, addAppId }) => ({
        url:
          type === "signup"
            ? "account/register/verify-email"
            : "account/reset-password/verify-email",
        method: "POST",
        body: { email, redirectUrl, addAppId },
        responseHandler: (response) => response.text(),
      }),
    }),

    verifyToken: build.query<ApiVerifyToken, ApiVerifyTokenArgs>({
      query: ({ token, type, redirectUrl }) => ({
        url:
          type === "signup"
            ? `account/register?token=${token}${
                redirectUrl ? `&redirectUrl=${redirectUrl}` : ""
              }`
            : `account/reset-password/validate?token=${token}`,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.email) {
            dispatch(userUpdated({ registrationEmail: data.email }));
          }
        } catch (e) {
          // NO-OP
        }
      },
    }),

    resetPassword: build.mutation<ApiSignin, ApiResetPasswordArgs>({
      query: (args) => ({
        url: "account/reset-password",
        method: "POST",
        body: args,
      }),
    }),
    changePassword: build.mutation<ApiChangePassword, ApiChangePasswordArgs>({
      query: (args) => ({
        url: "account/user/change-password",
        method: "POST",
        body: args,
        responseHandler: (response) => {
          if (response.ok) {
            return response.text();
          }
          return response.json();
        },
      }),
    }),

    updateUser: build.mutation<ApiUpdateUser, ApiUpdateUserArgs>({
      query: (args) => ({
        url: "account/user/settings/update",
        method: "POST",
        body: { firstName: args.firstName, lastName: args.lastName },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            userUpdated({ firstName: data.firstName, lastName: data.lastName })
          );
          dispatch(
            organizationMemberDetailsUpdated({
              firstName: data.firstName,
              lastName: data.lastName,
              userId: args.userId,
            })
          );
        } catch {
          // NO-OP
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetCurrentUserQuery,
  useRegisterMutation,
  useResetPasswordMutation,
  useSigninMutation,
  useSignoutMutation,
  useDeleteAccountMutation,
  useUpdateUserMutation,
  useVerifyEmailMutation,
  useVerifyTokenQuery,
  useChangePasswordMutation,
} = userApi;

export default userApi;

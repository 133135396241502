import { useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";

const useIsFullService = () => {
  const license = useAppSelector(selectLicense);
  return (
    license.app.status === "fullService" ||
    license.app.support === "fullService"
  );
};

export default useIsFullService;

export type PricingPackageCallback = (
  pricingPackage?: PricingPackage,
  query?: Record<string, string>
) => void;

export enum PricingPackage {
  Cart = "cart",
  SelfServe = "self-service",
  FullService = "full-service",
  Enterprise = "enterprise",
}

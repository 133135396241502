import urlJoin from "url-join";

import { EventName, trackEvent, trackLead } from "utils/analytics";
import urls from "utils/urls";

import { baseAxios } from "./utils";

export type DownloadLinkType = "aab" | "androidSource" | "apk" | "iosSource";

export interface DownloadBuildInput {
  privateKey: string;
  type: DownloadLinkType;
  eventName: EventName;
  email: string;
  licensed: boolean;
  leadKey: string;
}

export const download = async (args: DownloadBuildInput) => {
  const { privateKey, type, eventName, email, leadKey, licensed } = args;

  const downloadUrl = urlJoin(
    urls.base,
    `project/${privateKey}/download/${type}?nocache=${Date.now()}&redirect=false`
  );
  const response = await baseAxios.get(downloadUrl);

  trackEvent(eventName);
  trackLead({
    email,
    licensed,
    [leadKey]: true,
  });

  if (response.data?.signedUrl) window.location.href = response.data.signedUrl;
  else {
    window.location.href = urlJoin(
      urls.base,
      `project/${privateKey}/download/${type}?nocache=${Date.now()}`
    );
  }
};

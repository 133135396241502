import * as animation from "./animation";
import * as app from "./app";
import * as appetize from "./appetize";
import * as backups from "./backups";
import * as build from "./build";
import * as contact from "./contact";
import * as general from "./general";
import * as hubspot from "./hubspot";
import * as icons from "./icons";
import * as uploadUrls from "./uploadUrls";

export default {
  animation,
  app,
  appetize,
  backups,
  build,
  contact,
  general,
  hubspot,
  icons,
  uploadUrls,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";

import Input, { InputChangeEvent, InputProps } from "./index";
import styles from "./styles.module.scss";

export default function HookInput<T extends FieldValues = FieldValues>({
  onBlur = () => null,
  onChange = () => null,
  containerClassName,
  ...props
}: InputProps & UseControllerProps<T> & { containerClassName?: string }) {
  const {
    field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
    fieldState: { error },
  } = useController(props);
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    if (!error) return "";

    return t(`fields.validation.${error.type}`);
  }, [error, t]);

  const handleBlur = useCallback(
    (id: string | undefined) => {
      fieldOnBlur();
      onBlur(id);
    },
    [fieldOnBlur, onBlur]
  );

  const handleChange = useCallback(
    (event: InputChangeEvent<any>) => {
      fieldOnChange(event.value);
      onChange(event as any);
    },
    [fieldOnChange, onChange]
  );

  return (
    <div className={classNames(styles.formInputContainer, containerClassName)}>
      <Input
        className={classNames(styles.formInput, !!errorMessage && styles.error)}
        {...props}
        {...field}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {!!errorMessage && (
        <div
          className={classNames(
            styles.inputError,
            error?.message && styles.suggestion
          )}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
}

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends Record<string, any> {
  children?: string;
  className?: string;
  tag?: keyof JSX.IntrinsicElements;
}

const createMarkup = (content: string) => ({
  __html: content,
});

const RawContent: React.FC<Props> = ({
  children,
  tag = "div",
  ...otherProps
}) => {
  const Tag = tag as keyof JSX.IntrinsicElements;

  return (
    <Tag
      dangerouslySetInnerHTML={createMarkup(children || "")}
      {...otherProps}
    />
  );
};

export default RawContent;

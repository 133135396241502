import HTMLReactParser, { DOMNode } from "html-react-parser";
import dynamic from "next/dynamic";
import React from "react";

import AppHeaderDefault from "./AppHeaderDefault.html";
import AppHeaderLight from "./AppHeaderLight.html";

const SearchBar = dynamic(() => import("../SearchBar"));

const options = {
  replace: (domNode: DOMNode) => {
    if (
      "attribs" in domNode &&
      domNode.attribs.id === "search-bar-placeholder"
    ) {
      return <SearchBar />;
    }
    return null;
  },
};

interface Props {
  showSearchBar?: boolean;
  type?: "default" | "light";
}

const AppHeader: React.FC<Props> = ({ type = "default", showSearchBar }) => {
  const html = type === "light" ? AppHeaderLight : AppHeaderDefault;
  return <>{HTMLReactParser(html, showSearchBar ? options : undefined)}</>;
};

export default AppHeader;

import { useRouter } from "next/router";

const useBackupVersion = () => {
  const router = useRouter();
  const versionNumber = parseInt(router.query.version as string, 10);

  if (router.query.version) {
    return versionNumber;
  }

  return "";
};

export default useBackupVersion;

/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import Head from "next/head";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useMemo } from "react";
import { toast, ToastContainer } from "react-toastify";

import NotificationBanners from "components/common/NotificationBanners";
import ContactForm from "components/sections/Services/ContactForm";
import { useAppDispatch, useAppSelector } from "stores";
import {
  selectAppIcon,
  selectBranding,
} from "stores/features/appBuilder/branding";
import { selectAppName } from "stores/features/appBuilder/overview";
import { selectIsBackupPreview } from "stores/features/backups";
import { selectPrivateKey } from "stores/features/project";
import {
  selectAuthMessageType,
  selectIsFooterVisible,
  selectModalShown,
  uiUpdated,
} from "stores/features/ui";
import { selectIsAuthenticated } from "stores/features/user";
import { trackPageView } from "utils/analytics";
import { useImage, useOrganizationDetails } from "utils/hooks";
import { getSignedUrl } from "utils/s3";
import urls, { toRelativeUrl } from "utils/urls";

import AuthContainer from "../AuthContainer";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";
import AppScripts from "./AppScripts";
import styles from "./styles.module.scss";
import { useInit } from "./utils";

interface Props {
  authEnabled?: boolean;
  compressContent?: boolean;
  contactPage?: boolean;
  fontawesomeEnabled?: boolean;
  headerType?: "default" | "light";
  hideHeader?: boolean;
  hotJarEnabled?: boolean;
  showFooter?: boolean;
  showNotification?: boolean;
  title?: string;
  hideGroove?: boolean;
}

const App: React.FC<Props> = ({
  authEnabled,
  children,
  compressContent,
  contactPage,
  fontawesomeEnabled,
  headerType = "default",
  hideHeader,
  hotJarEnabled,
  showFooter,
  showNotification = false,
  title,
  hideGroove,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const branding = useAppSelector(selectBranding);
  const isFooterVisible = useAppSelector(selectIsFooterVisible);
  const authMessageType = useAppSelector(selectAuthMessageType);
  const modalShown = useAppSelector(selectModalShown);
  const appName = useAppSelector(selectAppName);
  const appIcon = useImage(useAppSelector(selectAppIcon));
  const customIconsFont = getSignedUrl(branding.customIcons?.font);
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const privateKey = useAppSelector(selectPrivateKey);
  const { organizationName } = useOrganizationDetails();

  const router = useRouter();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const pageTitle = useMemo(() => {
    const { asPath } = router;

    if (asPath === "/login") {
      return "Median.co – Sign in to your account";
    } else if (asPath === "/signup") {
      return "Median.co – Create an Account";
    } else if (asPath.startsWith("/organization")) {
      let baseTitle = "Median";
      let pathTitle = "";

      const pathSegments = asPath.split("/");
      const thirdSegment = pathSegments[3] || "";

      switch (thirdSegment) {
        case "apps":
          pathTitle = "App listing";
          break;
        case "members":
          pathTitle = "Organization members";
          break;
        case "settings":
          pathTitle = "Organization settings";
          break;
        default:
          pathTitle = "";
          break;
      }

      if (isAuthenticated) {
        baseTitle = `${organizationName} – ${pathTitle}`;
      } else {
        baseTitle = `Median – ${pathTitle}`;
      }

      return baseTitle;
    } else {
      return "Median - Convert your website to native Android & iOS";
    }
  }, [router, isAuthenticated, organizationName]);

  useInit();

  useEffect(() => {
    trackPageView(router.asPath);
  }, [router.asPath]);

  return (
    <>
      <Head>
        <title>{title || t("pages.app.defaultTitle")}</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Median makes apps easy for web professionals. Build and maintain full-feature native apps powered by web content, without the complexity of native development."
        />
        <meta
          property="og:description"
          content="Median makes apps easy for web professionals. Build and maintain full-feature native apps powered by web content, without the complexity of native development."
          key="og:description"
        />
        {appIcon && appName ? (
          <meta
            property="og:image"
            content={toRelativeUrl(appIcon)}
            key="og:image"
          />
        ) : (
          <meta
            property="og:image"
            content="https://cdn.median.co/images/opengraph-1.png"
            key="og:image"
          />
        )}
        {appName ? (
          <meta
            property="og:title"
            content={`${appName} - Median App Studio`}
            key="og:title"
          />
        ) : (
          <meta property="og:title" content={pageTitle} key="og:title" />
        )}

        {appName ? (
          <meta
            property="twitter:title"
            content={`${appName} - Median App Studio`}
            key="twitter:title"
          />
        ) : (
          <meta
            property="twitter:title"
            content={pageTitle}
            key="twitter:title"
          />
        )}
        <meta
          property="twitter:description"
          content="Median makes apps easy for web professionals. Build and maintain full-feature native apps powered by web content, without the complexity of native development."
          key="twitter:description"
        />

        {appIcon && appName ? (
          <meta
            property="twitter:image"
            content={appIcon}
            key="twitter:image"
          />
        ) : (
          <meta
            property="twitter:image"
            content="https://cdn.median.co/images/opengraph-1.png"
            key="twitter:image"
          />
        )}

        {customIconsFont && (
          <style>
            {`@font-face {
              font-family: 'custom-icons';
              src: url(${customIconsFont}) format('truetype');
              font-weight: normal;
              font-style: normal;
          }`}
          </style>
        )}

        {headerType === "default" ? (
          <link rel="stylesheet" href={urls.static("default.css")} />
        ) : (
          <link rel="stylesheet" href={urls.static("light.css")} />
        )}

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />

        <link rel="canonical" href={`https://median.co${router.asPath}`} />

        {fontawesomeEnabled && (
          <>
            <link
              href={urls.static("fontawesome/css/fontawesome.css")}
              rel="stylesheet"
            />
            <link
              href={urls.static("fontawesome/css/solid.css")}
              rel="stylesheet"
            />
            <link
              href={urls.static("fontawesome/css/brands.css")}
              rel="stylesheet"
            />
            <link
              href={urls.static("fontawesome/css/light.css")}
              rel="stylesheet"
            />
            <link
              href={urls.static("fontawesome/css/regular.css")}
              rel="stylesheet"
            />
            <link
              href={urls.static("fontawesome/css/solid.css")}
              rel="stylesheet"
            />
            <link
              href={urls.static("fontawesome/css/thin.css")}
              rel="stylesheet"
            />
          </>
        )}
      </Head>
      <ToastContainer
        className={styles.toastContainer}
        position={toast.POSITION.TOP_CENTER}
        toastClassName={styles.toast}
      />
      <div className={styles.container}>
        <div
          className={classNames(styles.header, !hideHeader && styles.visible)}
        >
          <AppHeader
            showSearchBar={!!privateKey && !isBackupPreview}
            type={headerType}
          />
        </div>

        {!isBackupPreview && showNotification && <NotificationBanners />}

        <div className={styles.content} id="__app-content">
          <div
            className={classNames(
              styles.main,
              compressContent && styles.compress,
              contactPage && styles.contact
            )}
          >
            {!authMessageType && children}

            <AuthContainer enabled={authEnabled} />
          </div>

          {(showFooter || isFooterVisible) && !authMessageType && <AppFooter />}
        </div>
      </div>
      <AppScripts hotJarEnabled={hotJarEnabled} grooveEnabled={!hideGroove} />
      <ContactForm
        isOpen={modalShown === "contactForm"}
        onClose={() => dispatch(uiUpdated({ modalShown: "none" }))}
      />
    </>
  );
};

export default App;

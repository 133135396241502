import classNames from "classnames";
import React, { forwardRef, useState } from "react";

import Button, {
  ButtonForwardRefType,
  ButtonProps,
} from "components/common/Button";
import InputLabel from "components/common/Input/InputLabel";

import styles from "./styles.module.scss";

interface BaseProps {
  action?: string;
  containerClassName?: string;
  hoverAction?: string;
  icon?: React.ReactNode;
  iconClassName?: string;
  label?: React.ReactNode;
  labelClassName?: string;
  subject?: string;
  subjectClassName?: string;
}

type Props = BaseProps & ButtonProps;

const ActionButton = forwardRef<ButtonForwardRefType, Props>(
  (
    {
      action,
      children,
      className,
      containerClassName,
      hoverAction,
      icon,
      isIcon,
      iconClassName,
      label,
      labelClassName,
      subject,
      subjectClassName,
      ...otherProps
    },
    ref
  ) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
      <div
        className={containerClassName}
        onMouseEnter={hoverAction ? () => setIsHovering(true) : undefined}
        onMouseLeave={hoverAction ? () => setIsHovering(false) : undefined}
      >
        <InputLabel className={labelClassName}>{label}</InputLabel>
        <Button
          className={classNames(
            styles.actionButton,
            icon && styles.withIcon,
            className
          )}
          ref={ref}
          {...otherProps}
        >
          {icon && (
            <span
              className={classNames(styles.actionButtonIcon, iconClassName)}
            >
              {icon}
            </span>
          )}
          <span
            className={classNames(
              styles.actionButtonText,
              icon && styles.withIcon,
              subjectClassName
            )}
          >
            {[isHovering ? hoverAction : action, subject].join(" ")}
          </span>
        </Button>
      </div>
    );
  }
);

export default ActionButton;

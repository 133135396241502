import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import unset from "lodash/unset";
import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "stores";
import { AppBuilderState } from "stores/features/appBuilder/constants";
import { selectAppBuilder } from "stores/features/appBuilder/utils";
import { AppConfigState } from "stores/features/appConfig";
import {
  PendingUploadItem,
  pendingUploadsUpdated,
  selectMeta,
} from "stores/features/meta";
import { selectPublicKey } from "stores/features/project";
import api from "utils/api";
import { copyTempToAppFiles } from "utils/s3";

interface GetModifiedAppBuilderOptions {
  temporaryUpload?: boolean;
}

interface CustomFilesUploadData {
  content: string;
  extension: string;
  filename: string;
  tempDir?: boolean;
  type: string;
}

const useAppBuilderManager = () => {
  const dispatch = useAppDispatch();
  const publicKey = useAppSelector(selectPublicKey);
  const baseAppBuilder = useAppSelector(selectAppBuilder);
  const { pendingUploads } = useAppSelector(selectMeta);

  const handleCustomFilesUpload = useCallback(
    async (data: CustomFilesUploadData) => {
      const { content, filename, tempDir, type, extension } = data;
      const blob = new Blob([content], { type });
      const file = new File([blob], filename, { type });

      return api.general.upload({
        extension,
        file,
        publicKey,
        tempDir,
      });
    },
    [publicKey]
  );

  const handleUpload = useCallback(
    async (
      item: PendingUploadItem,
      appBuilder: AppBuilderState,
      temporaryUpload?: boolean
    ) => {
      try {
        const value = get(appBuilder, item.valueKey);
        if (!value) {
          return;
        }

        if (temporaryUpload) {
          // Return tempUrl if already uploaded
          if (item.tempUrl) {
            set(appBuilder, item.urlKey, item.tempUrl);
            return;
          }

          // Upload to tempDir
          const tempUrl = await handleCustomFilesUpload({
            content: value,
            extension: item.extension,
            filename: item.filename,
            type: item.fileType,
            tempDir: true,
          });
          set(appBuilder, item.urlKey, tempUrl);
          dispatch(pendingUploadsUpdated({ ...item, tempUrl }));
          return;
        }

        // Copy temp file to app_files
        if (item.tempUrl) {
          const url = await copyTempToAppFiles(item.tempUrl);
          set(appBuilder, item.urlKey, url);
          return;
        }

        // Upload file to app_files
        const uploadedUrl = await handleCustomFilesUpload({
          content: value,
          extension: item.extension,
          filename: item.filename,
          type: item.fileType,
        });
        set(appBuilder, item.urlKey, uploadedUrl);
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, handleCustomFilesUpload]
  );

  const addPendingUpload = useCallback(
    async (data: PendingUploadItem | { deleteKey: string }) => {
      dispatch(pendingUploadsUpdated({ ...data }));
    },
    [dispatch]
  );

  const getModifiedAppBuilder = useCallback(
    async (options: GetModifiedAppBuilderOptions) => {
      const { temporaryUpload } = options;

      const clonedAppBuilder = cloneDeep(baseAppBuilder);
      const uploads = Object.values(pendingUploads);

      await Promise.all(
        uploads.map((item) =>
          handleUpload(item, clonedAppBuilder, temporaryUpload)
        )
      );

      [
        {
          url: "websiteOverrides.customCssUrl.base",
          value: "websiteOverrides.customCss.base",
        },
        {
          url: "websiteOverrides.customCssUrl.ios",
          value: "websiteOverrides.customCss.ios",
        },
        {
          url: "websiteOverrides.customCssUrl.android",
          value: "websiteOverrides.customCss.android",
        },
        {
          url: "websiteOverrides.customJsUrl.base",
          value: "websiteOverrides.customJs.base",
        },
        {
          url: "websiteOverrides.customJsUrl.ios",
          value: "websiteOverrides.customJs.ios",
        },
        {
          url: "websiteOverrides.customJsUrl.android",
          value: "websiteOverrides.customJs.android",
        },
        {
          url: "interface.offlinePage.ios.offlinePageUrl",
          value: "interface.offlinePage.ios.html",
        },
        {
          url: "interface.offlinePage.android.offlinePageUrl",
          value: "interface.offlinePage.android.html",
        },
      ].forEach((item) => {
        const url = get(clonedAppBuilder, item.url);
        const value = get(clonedAppBuilder, item.value);
        if (!value) {
          unset(clonedAppBuilder, item.url);
          unset(clonedAppBuilder, item.value);
        } else if (url) {
          unset(clonedAppBuilder, item.value);
        }
      });

      return clonedAppBuilder;
    },
    [baseAppBuilder, handleUpload, pendingUploads]
  );

  const getModifiedAppConfig = useCallback(
    async (appConfig: AppConfigState) => {
      const clonedAppConfig = cloneDeep(appConfig);
      const uploads = Object.values(pendingUploads);

      await Promise.all(
        uploads.map(async (item) => {
          if (item.tempUrl) {
            const url = await copyTempToAppFiles(item.tempUrl);
            set(clonedAppConfig, item.appConfigKey, url);
          }
        })
      );

      return clonedAppConfig;
    },
    [pendingUploads]
  );

  return {
    addPendingUpload,
    getModifiedAppBuilder,
    getModifiedAppConfig,
    pendingUploads,
  };
};

export default useAppBuilderManager;

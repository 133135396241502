import classNames from "classnames";
import React, { forwardRef, useCallback } from "react";

import Loader from "components/common/Loader";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface UrlInputProps {
  onChange?: (event: InputChangeEvent) => void;
  placeholder?: string;
  type: "url";
  value?: string | null;
}

type Props = BaseProps & UrlInputProps;

const UrlInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoFocus,
      className,
      dark,
      disabled,
      id,
      inline,
      label,
      labelClassName,
      labelSize,
      loading,
      name,
      onBlur = () => null,
      onChange = () => null,
      onFocus,
      placeholder,
      readOnly,
      required,
      value,
    },
    ref
  ) => {
    const handleBlur = useCallback(() => {
      onBlur(id);
    }, [id, onBlur]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let nextValue = event.target.value.trim();

        if (!value && !nextValue.startsWith("https://")) {
          nextValue = `https://${nextValue}`;
        } else if (nextValue.startsWith("https://https://")) {
          nextValue = nextValue.slice(8);
        }

        onChange({ id, name, value: nextValue });
      },
      [id, name, onChange, value]
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(id, e);
        } else if (!value || value.trim().length === 0) {
          onChange({ id, name, value: "https://" });
        }
      },
      [id, name, onChange, onFocus, value]
    );

    return (
      <label
        className={classNames(
          styles.container,
          { [styles.inline]: inline },
          className
        )}
        htmlFor={id}
      >
        <InputLabel
          className={classNames(inline && styles.labelInline, labelClassName)}
          size={labelSize}
        >
          {label}
        </InputLabel>

        <div className={styles.inputContainer}>
          <input
            autoFocus={autoFocus}
            className={classNames(styles.textInput, { [styles.dark]: dark })}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            required={required}
            type="url"
            value={value || ""}
          />

          <Loader className={styles.loader} disabled={!loading} />
        </div>
      </label>
    );
  }
);

export default UrlInput;

import { getSignedUrl } from "utils/s3";

import { baseAxios } from "./utils";

interface GetExternalHtml {
  url: string;
  status?: string;
  error?: string;
}

interface GetUrlContentArgs {
  platform: "ios" | "android";
  privateKey: string;
  url: string;
}

export const readContent = async (url: string | undefined) => {
  const signedUrl = getSignedUrl(url);
  if (!signedUrl) return "";
  try {
    const { data } = await baseAxios.get<string>(signedUrl);
    return data;
  } catch {
    return "";
  }
};

export const getHtmlFromUrl = async (args: GetUrlContentArgs) => {
  const { platform, privateKey, url } = args;
  if (!url) {
    return { html: "", url: "" };
  }
  const { data } = await baseAxios.post<GetExternalHtml>(
    `/project/${privateKey}/getExternalHtml?platform=${platform}`,
    { url: getSignedUrl(url) }
  );

  if (data.status === "req-error") {
    throw new Error(data.error);
  }

  const html = await readContent(data.url);
  return { html, url: data.url };
};

/* eslint-disable prefer-destructuring */

import { camelize } from "humps";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";

import { getColorFromString } from "components/common/ProfilePicture/utils";
import { useSwitchCurrentOrganizationMutation } from "services/api/organization";
import { useSignoutMutation } from "services/api/user";
import { useAppDispatch, useAppSelector } from "stores";
import { selectAppUsers } from "stores/features/app";
import { selectPrivateKey } from "stores/features/project";
import { uiUpdated } from "stores/features/ui";
import {
  selectIsAuthenticated,
  selectIsSuperUser,
  selectUser,
  selectUserCurrentOrganizationId,
  selectUserId,
} from "stores/features/user";
import { ORGSECTION } from "utils/constants";
import { setCookie } from "utils/cookie";
import { useOrganizationDetails } from "utils/hooks";
import { StorageAuth, storageKeys } from "utils/storage";
import urls from "utils/urls";

export const useAuthentication = (enabled?: boolean) => {
  const [, setUserAccount, clearStorageAuth] = useLocalStorage<StorageAuth>(
    storageKeys.auth()
  );
  const [signout] = useSignoutMutation();
  const [switchOrganization] = useSwitchCurrentOrganizationMutation();
  const router = useRouter();
  const isNewApp = router.pathname === "/app";

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userId = useAppSelector(selectUserId);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isSuperUser = useAppSelector(selectIsSuperUser);
  const userCurrentOrganizationId = useAppSelector(
    selectUserCurrentOrganizationId
  );
  const { organizationId, organizationName } = useOrganizationDetails();
  const currentOrganizationId = organizationId || userCurrentOrganizationId;

  const showSigninButton =
    router.asPath !== "/signup" && router.asPath !== "/login";

  const currentOrganizationName = useMemo(() => {
    if (organizationName) return organizationName;
    return (
      user.organizations.find((item) => item.id === currentOrganizationId)
        ?.name || ""
    );
  }, [currentOrganizationId, organizationName, user.organizations]);

  const handleSignin = useCallback(() => {
    dispatch(uiUpdated({ authModalType: isNewApp ? "signup" : "signin" }));
  }, [dispatch, isNewApp]);

  const handleSignout = useCallback(async () => {
    signout();
    clearStorageAuth();
    window.location.href = urls.login;
  }, [clearStorageAuth, signout]);

  const handleUserInfoButtonClick = useCallback(async () => {
    window.location.href = urls.accountUrl(userId);
  }, [userId]);

  const handleAddOrganization = useCallback(() => {
    dispatch(uiUpdated({ authModalType: "addOrg" }));
  }, [dispatch]);

  // Analytics
  useEffect(() => {
    if (isSuperUser) {
      setCookie("analytics-ignore", true, 31536000000);
    }
  }, [isSuperUser]);

  // Signin
  useEffect(() => {
    const signinButtonContainer = document.getElementById(
      "sign-in-button-container"
    );
    if (enabled && !isAuthenticated && signinButtonContainer) {
      signinButtonContainer.addEventListener("click", handleSignin);
    }
    return () => {
      if (signinButtonContainer) {
        signinButtonContainer.removeEventListener("click", handleSignin);
      }
    };
  }, [enabled, handleSignin, isAuthenticated]);

  // Signout
  useEffect(() => {
    const signoutButtonContainer = document.getElementById(
      "sign-out-button-container"
    );
    if (enabled && isAuthenticated && signoutButtonContainer) {
      signoutButtonContainer.addEventListener("click", handleSignout);
    }
    return () => {
      if (signoutButtonContainer) {
        signoutButtonContainer.removeEventListener("click", handleSignout);
      }
    };
  }, [enabled, handleSignout, isAuthenticated]);

  // Add Organization
  useEffect(() => {
    const addOrganizationButtonContainer = document.getElementById(
      "add-organization-button-container"
    );
    if (enabled && isAuthenticated && addOrganizationButtonContainer) {
      addOrganizationButtonContainer.addEventListener(
        "click",
        handleAddOrganization
      );
    }
    return () => {
      if (addOrganizationButtonContainer) {
        addOrganizationButtonContainer.removeEventListener(
          "click",
          handleAddOrganization
        );
      }
    };
  }, [enabled, handleAddOrganization, isAuthenticated]);

  useEffect(() => {
    const userInfoButtonContainer = document.querySelector(".user-info");
    if (enabled && isAuthenticated && userInfoButtonContainer) {
      userInfoButtonContainer.addEventListener(
        "click",
        handleUserInfoButtonClick
      );
    }

    return () => {
      if (userInfoButtonContainer) {
        userInfoButtonContainer.removeEventListener(
          "click",
          handleUserInfoButtonClick
        );
      }
    };
  }, [enabled, handleUserInfoButtonClick, isAuthenticated]);

  // Update menu bar buttons
  useEffect(() => {
    const userNameContainer = document.querySelector(".user-name");
    const userAvatarContainer = document.querySelector(".user-avatar");
    const userDropdownAvatarContainer = document.querySelector(
      ".user-avatar.dropdown-avatar"
    );
    const emailContainer = document.querySelector(".user-email");
    const uiToolbarContainer = document.getElementById("ui-toolbar");
    const signinButtonContainer = document.getElementById(
      "sign-in-button-container"
    );
    const appMenuDividerContainer = document.querySelector(
      ".app-menu-divider.margin-bot-16"
    ) as HTMLElement;
    const userDropdownContentContainer = document.querySelector(
      ".user-dropdown-main-content"
    ) as HTMLElement;

    const appsButtonContainer = document.getElementById(
      "apps-button-container"
    ) as HTMLAnchorElement;
    const createAppButtonContainer = document.getElementById(
      "create-app-button-container"
    ) as HTMLAnchorElement;
    const membersButtonContainer = document.getElementById(
      "members-button-container"
    ) as HTMLAnchorElement;
    const settingsButtonContainer = document.getElementById(
      "settings-button-container"
    ) as HTMLAnchorElement;

    if (!enabled) {
      return;
    }
    if (isAuthenticated) {
      if (
        user.firstName &&
        userNameContainer &&
        userAvatarContainer &&
        userDropdownAvatarContainer
      ) {
        userNameContainer.textContent = user.firstName;
        userAvatarContainer.textContent = user.firstName[0];
        userDropdownAvatarContainer.textContent = user.firstName[0];
      }
      if (user.email && emailContainer) {
        emailContainer.textContent = user.email;
      }
      if (
        currentOrganizationId &&
        appsButtonContainer &&
        createAppButtonContainer &&
        membersButtonContainer &&
        settingsButtonContainer
      ) {
        appsButtonContainer.href = urls.organization(
          currentOrganizationId,
          ORGSECTION.apps
        );
        membersButtonContainer.href = urls.organization(
          currentOrganizationId,
          ORGSECTION.members
        );
        settingsButtonContainer.href = urls.accountUrl(userId);
      }
      if (uiToolbarContainer) {
        uiToolbarContainer.style.display = "inline-block";
      }
      if (signinButtonContainer) {
        signinButtonContainer.style.display = "none";
      }

      if (
        appsButtonContainer &&
        membersButtonContainer &&
        createAppButtonContainer &&
        settingsButtonContainer &&
        appMenuDividerContainer &&
        userDropdownContentContainer &&
        user.organizations.length === 0
      ) {
        createAppButtonContainer.style.display = "none";
        membersButtonContainer.style.display = "none ";
        settingsButtonContainer.style.display = "none ";
        appMenuDividerContainer.style.display = "none ";
        userDropdownContentContainer.style.border = "none ";
      }
    } else {
      if (uiToolbarContainer) {
        uiToolbarContainer.style.display = "none";
      }
      if (signinButtonContainer) {
        signinButtonContainer.textContent = isNewApp ? "Sign Up" : "Sign In";
        signinButtonContainer.style.display = "flex";
      }

      if (!showSigninButton && signinButtonContainer) {
        signinButtonContainer.style.display = "none";
      }
    }
  }, [
    currentOrganizationId,
    enabled,
    isAuthenticated,
    isNewApp,
    showSigninButton,
    user.email,
    user.firstName,
    user.organizations.length,
    userId,
  ]);

  // Organization list
  useEffect(() => {
    const currentOrganizationContainer = document.getElementById(
      "current-organization-name"
    );
    const navMenuContainer = document.getElementById("w-dropdown-list-1");
    const appMenuBackContainer = document.querySelector(".app-menu-back");

    if (
      enabled &&
      isAuthenticated &&
      currentOrganizationContainer &&
      navMenuContainer
    ) {
      currentOrganizationContainer.textContent = currentOrganizationName;

      user.organizations
        .slice()
        .reverse()
        .forEach((organization) => {
          // Delete existing element
          const existingItem = document.getElementById(
            `organization-${organization.id}`
          );
          if (existingItem) {
            navMenuContainer.removeChild(existingItem);
          }

          // Create new element
          const link = document.createElement("div");
          link.id = `organization-${organization.id}`;
          link.className =
            currentOrganizationId === organization.id
              ? "app-menu-link active organization-link"
              : "app-menu-link organization-link";

          const div = document.createElement("div");
          div.className = "text-block-21";

          div.textContent =
            currentOrganizationId === organization.id
              ? currentOrganizationName
              : organization.name;
          link.appendChild(div);

          if (currentOrganizationId === organization.id) {
            const img = document.createElement("img");
            img.src =
              "https://uploads-ssl.webflow.com/6462308e001a70f67d5e9d4d/64d415827dde2db7746e6fd9_check-circle-white.svg";
            img.alt = "";
            img.className = "selected-organization";
            img.loading = "lazy";
            link.appendChild(img);
          }

          // Event listener for switching organizations
          link.addEventListener("click", () => {
            try {
              switchOrganization(organization.id);
              window.location.href = urls.organization(organization.id);
            } catch {
              // NO OP
            }
          });

          // Insert the new link into the menu
          if (appMenuBackContainer) {
            navMenuContainer.insertBefore(
              link,
              appMenuBackContainer.nextSibling
            );
          } else if (navMenuContainer.firstChild) {
            navMenuContainer.insertBefore(link, navMenuContainer.firstChild);
          }
        });
    }
  }, [
    currentOrganizationId,
    currentOrganizationName,
    enabled,
    isAuthenticated,
    switchOrganization,
    user.organizations,
  ]);

  // Set user account in LocalStorage
  useEffect(() => {
    if (isAuthenticated && enabled) {
      setUserAccount({
        name: user.firstName,
        firstName: user.firstName,
        lastName: user.lastName,
        backgroundColor: getColorFromString(user.userId),
        email: user.email,
        organizations: user.organizations,
        accountUrl: urls.accountUrl(user.userId),
      });
    }
  }, [enabled, isAuthenticated, setUserAccount, user]);

  const handleForcedSignout = useCallback(async () => {
    if (
      isAuthenticated &&
      window &&
      window.localStorage &&
      typeof window !== "undefined" &&
      typeof window.localStorage !== "undefined" &&
      !window.localStorage.getItem(storageKeys.auth())
    ) {
      dispatch(
        uiUpdated({ authModalType: "signout-placeholder/forcedSignout" })
      );
      window.location.reload();
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener("storage", handleForcedSignout, false);
    }

    return () => {
      window.removeEventListener("storage", handleForcedSignout, false);
    };
  }, [enabled, handleForcedSignout]);
};

export const useClaimApp = () => {
  const router = useRouter();
  const { query } = router;

  const dispatch = useAppDispatch();
  const privateKey = useAppSelector(selectPrivateKey);
  const appUsers = useAppSelector(selectAppUsers);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const isClaimable = useMemo(
    () => !!privateKey && appUsers.length === 0,
    [appUsers.length, privateKey]
  );

  const isClaimSection = useMemo(() => {
    let sections = (query.sections as string[] | undefined) || [];

    if (!query.privateKey && isClaimable) {
      sections = sections.slice(1).map((section) => camelize(section));
      return sections[0] === "claim";
    }

    return false;
  }, [query.sections, query.privateKey, isClaimable]);

  const claimAppWall = useCallback(
    (callback?: () => void) => {
      if (isClaimable) {
        dispatch(
          uiUpdated({
            authModalOpened: !isAuthenticated ? "server" : null,
            authModalType: isAuthenticated ? "claimApp" : "signin/claimApp",
            progressiveLogin: !isAuthenticated,
          })
        );
        return;
      }

      if (callback) {
        callback();
      }
    },
    [dispatch, isAuthenticated, isClaimable]
  );

  return {
    claimAppWall,
    isClaimSection,
    isClaimable,
  };
};

import classNames from "classnames";
import Link from "next/link";
import React, { ForwardedRef, forwardRef } from "react";

import IconNewWindow from "assets/images/icons/IconNewWindow";
import Loader from "components/common/Loader";
import { useAppSelector } from "stores";
import { selectIsBackupPreview } from "stores/features/backups";

import styles from "./styles.module.scss";

export type ButtonSize = "lg" | "md" | "sm";

export type ButtonType =
  | "primary"
  | "secondary"
  | "basic"
  | "danger"
  | "light"
  | "link"
  | "outlined"
  | "text";

interface AnchorProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href?: string | null;
  shallow?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
}

export interface ButtonBaseProps {
  allowOnPreviewMode?: boolean;
  buttonClassName?: string;
  disabled?: boolean;
  isSubmit?: boolean;
  loaderColor?: string;
  loading?: boolean;
  newWindow?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  wide?: boolean;
  isIcon?: boolean;
}

export type ButtonForwardRefType = HTMLAnchorElement | HTMLButtonElement;

export type ButtonProps = ButtonBaseProps &
  (AnchorProps | React.HTMLAttributes<HTMLButtonElement>);

const Button = forwardRef<ButtonForwardRefType, ButtonProps>((props, ref) => {
  const isBackupPreview = useAppSelector(selectIsBackupPreview);

  if ((props as ButtonBaseProps & AnchorProps).href && !props.disabled) {
    const {
      buttonClassName,
      children,
      className,
      disabled,
      href,
      loaderColor,
      loading,
      newWindow,
      shallow,
      size,
      type,
      wide,
      allowOnPreviewMode,
      isIcon,
      ...linkProps
    } = props as ButtonBaseProps & AnchorProps;

    return (
      <Link href={href || ""} shallow={shallow}>
        <a
          className={classNames(
            buttonClassName,
            styles.button,
            {
              [styles.basic]: type === "basic",
              [styles.text]: type === "text",
              [styles.danger]: type === "danger",
              [styles.disabled]: disabled,
              [styles.light]: type === "light",
              [styles.link]: type === "link",
              [styles.outlined]: type === "outlined",
              [styles.primary]: type === "primary",
              [styles.secondary]: type === "secondary",
              [styles.sm]: size === "sm",
              [styles.lg]: size === "lg",
              [styles.wide]: wide,
            },
            isIcon && styles.icon,
            className
          )}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          {...(linkProps as React.HTMLAttributes<HTMLAnchorElement>)}
        >
          {children}
          {newWindow && <IconNewWindow className={styles.urlIcon} />}
          {loading && (
            <Loader
              className={styles.loader}
              color={loaderColor || (type === "primary" ? "#fff" : "#0e0d08")}
              size="sm"
            />
          )}
        </a>
      </Link>
    );
  }

  const {
    buttonClassName,
    children,
    className,
    allowOnPreviewMode,
    disabled,
    isSubmit,
    loaderColor,
    loading,
    size = "md",
    type,
    wide,
    isIcon,
    ...otherProps
  } = props as ButtonBaseProps & ButtonProps;

  return (
    <button
      className={classNames(
        buttonClassName,
        styles.button,
        {
          [styles.basic]: type === "basic",
          [styles.text]: type === "text",
          [styles.danger]: type === "danger",
          [styles.light]: type === "light",
          [styles.link]: type === "link",
          [styles.outlined]: type === "outlined",
          [styles.primary]: type === "primary",
          [styles.secondary]: type === "secondary",
          [styles.sm]: size === "sm",
          [styles.lg]: size === "lg",
          [styles.wide]: wide,
        },
        isIcon && styles.icon,
        className
      )}
      disabled={disabled || (isBackupPreview && !allowOnPreviewMode)}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      type={isSubmit ? "submit" : "button"}
      {...(otherProps as React.HTMLAttributes<HTMLButtonElement>)}
    >
      {children}
      {loading && (
        <Loader
          className={styles.loader}
          color={loaderColor || (type === "primary" ? "#fff" : "#0e0d08")}
          size="sm"
        />
      )}
    </button>
  );
});

export default Button;

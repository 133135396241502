import React, { SVGProps } from "react";

const IconCheckboxCheck: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.75 2.25H5.25C4.45462 2.25087 3.69206 2.56722 3.12964 3.12964C2.56722 3.69206 2.25087 4.45462 2.25 5.25V18.75C2.25087 19.5454 2.56722 20.3079 3.12964 20.8704C3.69206 21.4328 4.45462 21.7491 5.25 21.75H18.75C19.5454 21.7491 20.3079 21.4328 20.8704 20.8704C21.4328 20.3079 21.7491 19.5454 21.75 18.75V5.25C21.7491 4.45462 21.4328 3.69206 20.8704 3.12964C20.3079 2.56722 19.5454 2.25087 18.75 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z"
      fill="#1A100B"
    />
  </svg>
);

export default IconCheckboxCheck;

/* eslint-disable @typescript-eslint/no-explicit-any */
import MaterialTooltip from "@mui/material/Tooltip";
import React, { useCallback, useState } from "react";

import styles from "./styles.module.scss";

interface TooltipProps {
  children: React.ReactNode;
  containerClassName?: string;
  disabled?: boolean;
  label: string;
  onHide?: () => void;
  onShow?: () => void;
  xOffset?: number;
  yOffset?: number;
  placement?: "left" | "right" | "top" | "bottom";
}

const Tooltip: React.FC<TooltipProps> = ({
  label,
  children,
  containerClassName,
  disabled = false,
  onHide = () => null,
  onShow = () => null,
  xOffset = 0,
  yOffset = 0,
  placement = "right",
}) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = useCallback(() => {
    setVisible(true);
    onShow();
  }, [onShow]);

  const hideTooltip = useCallback(() => {
    setVisible(false);
    onHide();
  }, [onHide]);

  return (
    <MaterialTooltip
      open={!disabled && visible}
      onClose={hideTooltip}
      onOpen={showTooltip}
      title={label}
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      placement={placement}
      className={containerClassName}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [xOffset, yOffset - 12],
              },
            },
          ],
        },
      }}
    >
      {children as any}
    </MaterialTooltip>
  );
};

export default Tooltip;

import classNames from "classnames";
import React from "react";

import Modal, { ModalProps } from "components/common/Modal";

import Loader from "../Loader";
import ProgressLoader from "../Loader/ProgressLoader";
import styles from "./styles.module.scss";

export interface Props extends ModalProps {
  averageDelay?: number;
  completed?: boolean;
  onFinish?: () => void;
  showProgress?: boolean;
}

const LoadingModal: React.FC<Props> = ({
  averageDelay,
  className,
  completed,
  footerClassName,
  onFinish,
  showProgress,
  ...props
}) => (
  <Modal
    {...props}
    className={classNames(styles.loadingModal, className)}
    footerClassName={classNames(styles.footer, footerClassName)}
  >
    {showProgress ? (
      <ProgressLoader
        averageDelay={averageDelay}
        className={classNames(styles.loader, styles.progress)}
        completed={completed}
        onFinish={onFinish}
      />
    ) : (
      <Loader className={styles.loader} size="lg" />
    )}
  </Modal>
);

export default LoadingModal;

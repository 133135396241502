import clamp from "lodash/clamp";
import isNaN from "lodash/isNaN";

import { Color } from "./constants";

export const getColorCoordinates = (
  color: Color,
  width: number,
  height: number
) => {
  const { s, v } = color.hsv;
  const x = (s / 100) * width;
  const y = ((100 - v) / 100) * height;
  return [x, y];
};

export const getHueCoordinates = (height: number, width: number) =>
  (height / 360) * width;

export const getAlphaCoordinates = (alpha: number, width: number) =>
  alpha * width;

export const toHex = (value: string): Color["hex"] => {
  if (!value.startsWith("#")) {
    const ctx = document.createElement("canvas").getContext("2d");
    if (!ctx) return "#ffffff";

    ctx.fillStyle = value;

    return ctx.fillStyle;
  }
  if (value.length === 4 || value.length === 5) {
    return value
      .split("")
      .map((v, i) => (i ? v + v : "#"))
      .join("");
  }
  if (value.length === 7 || value.length === 9) {
    return value;
  }
  return "#000000";
};

export const toRgb = (value: string[]): Color["rgb"] => {
  const [r, g, b, a] = value.map((v, i) =>
    clamp(Number(v), 0, i < 3 ? 255 : 1)
  );
  return { r, g, b, a };
};

export const toHsv = (value: string[]): Color["hsv"] => {
  const [h, s, v, a] = value.map((i, j) => {
    if (!j) return clamp(Number(i), 0, 360);
    if (j < 3) return clamp(Number(i), 0, 100);
    return clamp(Number(i), 0, 1);
  });
  return { h, s, v, a };
};

export const hex2rgb = (hex: Color["hex"]): Color["rgb"] => {
  const newHex = hex.slice(1);
  const r = parseInt(newHex.slice(0, 2), 16);
  const g = parseInt(newHex.slice(2, 4), 16);
  const b = parseInt(newHex.slice(4, 6), 16);
  const alpha = parseInt(newHex.slice(6, 8), 16);
  let a = isNaN(alpha) ? undefined : alpha;
  if (a) a /= 255;
  return { r, g, b, a };
};

export const rgb2hsv = (input: Color["rgb"]): Color["hsv"] => {
  const { r, g, b, a } = input;
  const red = r / 255;
  const green = g / 255;
  const blue = b / 255;

  const max = Math.max(red, green, blue);
  const d = max - Math.min(red, green, blue);

  let h = 0;
  if (d) {
    if (max === red) h = (green - blue) / d + (green < blue ? 6 : 0);
    else if (max === green) h = 2 + (blue - red) / d;
    else h = 4 + (red - green) / d;
    h *= 60;
  }
  const s = max ? (d / max) * 100 : 0;
  const v = max * 100;

  return { h, s, v, a };
};

export const hsv2rgb = (input: Color["hsv"]): Color["rgb"] => {
  const { h, s, v, a } = input;
  const sat = s / 100;
  const value = v / 100;

  // eslint-disable-next-line no-bitwise
  const i = ~~(h / 60);
  const f = h / 60 - i;
  const p = value * (1 - sat);
  const q = value * (1 - sat * f);
  const t = value * (1 - sat * (1 - f));
  const index = i % 6;

  const r = Math.round([value, q, p, p, t, value][index] * 255);
  const g = Math.round([t, value, value, q, p, p][index] * 255);
  const b = Math.round([p, p, t, value, value, q][index] * 255);

  return { r, g, b, a };
};

export const rgb2hex = ({ r, g, b, a }: Color["rgb"]): Color["hex"] => {
  const hex = [r, g, b, a]
    .map((v, i) =>
      v !== undefined
        ? (i < 3 ? v : Math.round(v * 255)).toString(16).padStart(2, "0")
        : ""
    )
    .join("");

  return `#${hex}`;
};

export const roundFloat = (n: number, decimalPlaces: number) =>
  Math.round(n * 10 ** decimalPlaces) / 10 ** decimalPlaces;

export function toColor(model: "hex", color: Color["hex"]): Color;
export function toColor(model: "rgb", color: Color["rgb"]): Color;
export function toColor(model: "hsv", color: Color["hsv"]): Color;

export function toColor<M extends keyof Color, C extends Color[M]>(
  model: M,
  color: C
): Color {
  let hex: Color["hex"] = toHex("#121212");
  let rgb: Color["rgb"] = hex2rgb(hex);
  let hsv: Color["hsv"] = rgb2hsv(rgb);

  if (model === "hex") {
    const value = color as Color["hex"];

    hex = toHex(value);
    rgb = hex2rgb(hex);
    hsv = rgb2hsv(rgb);
  } else if (model === "rgb") {
    const value = color as Color["rgb"];

    rgb = value;
    hex = rgb2hex(rgb);
    hsv = rgb2hsv(rgb);
  } else if (model === "hsv") {
    const value = color as Color["hsv"];

    hsv = value;
    rgb = hsv2rgb(hsv);
    hex = rgb2hex(rgb);
  }

  return { hex, rgb, hsv };
}

export function validHex(value: string): boolean {
  if (value.startsWith("#")) {
    const newValue = value.slice(1);
    return /[\dA-F]/i.test(newValue[newValue.length - 1]);
  }

  return (/\w/.test(value) && !/\d/.test(value)) || value === "";
}

import clamp from "lodash/clamp";
import React, { useCallback, useRef } from "react";

export interface InteractiveOnChangeProps {
  readonly x: number;
  readonly y: number;
  readonly complete?: boolean;
}

export interface InteractiveProps {
  readonly className: string;
  readonly style?: React.CSSProperties;
  readonly onChange: ({ x, y, complete }: InteractiveOnChangeProps) => void;
  readonly children: React.ReactNode;
}

const ColorPickerInteractive: React.FC<InteractiveProps> = ({
  className,
  style,
  onChange,
  children,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const move = useCallback(
    (event, complete) => {
      if (divRef.current) {
        const { current: div } = divRef;
        const { width, height, left, top } = div.getBoundingClientRect();

        const x = clamp(event.clientX - left, 0, width);
        const y = clamp(event.clientY - top, 0, height);

        onChange({ x, y });

        if (complete) onChange({ x, y, complete: true });
      }
    },
    [onChange]
  );

  const onMouseDown = useCallback(
    (event) => {
      if (event.button !== 0) return;

      move(event, false);

      const onMouseMove = (e: MouseEvent) => move(e, false);

      const onMouseUp = (e: MouseEvent) => {
        document.removeEventListener("mousemove", onMouseMove, false);
        document.removeEventListener("mouseup", onMouseUp, false);

        move(e, true);
      };

      document.addEventListener("mousemove", onMouseMove, false);
      document.addEventListener("mouseup", onMouseUp, false);
    },
    [move]
  );

  return (
    <div
      ref={divRef}
      className={className}
      style={style}
      onMouseDown={onMouseDown}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default ColorPickerInteractive;

import get from "lodash/get";
import isString from "lodash/isString";
import { useMemo } from "react";

import { useGetHelpInfoQuery } from "services/api/helpInfos";
import urls from "utils/urls";

const useHelpInfo = (configKey?: string) => {
  const { data: helpInfo = {} } = useGetHelpInfoQuery();

  const message = useMemo(() => {
    let value = "";

    if (helpInfo && configKey) {
      value = get(helpInfo, `${configKey}.text`);
    }

    if (!isString(value)) {
      value = "";
    }

    return value;
  }, [configKey, helpInfo]);

  const link = useMemo(() => {
    let value = "";

    if (helpInfo && configKey) {
      value = get(helpInfo, `${configKey}.slug`);
    }

    if (!value || !isString(value)) {
      return "";
    }

    return urls.documentation(value);
  }, [configKey, helpInfo]);

  return [message, link];
};

export default useHelpInfo;

import { Placement } from "@popperjs/core";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";

import Button from "components/common/Button";
import Portal from "components/common/Portal";
import { useClickOutside } from "utils/hooks";

import styles from "./styles.module.scss";

interface Props {
  cancelText?: React.ReactNode;
  children: React.ReactNode;
  confirmText?: React.ReactNode;
  disabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  placement?: Placement;
  title?: React.ReactNode;
  confirmButtonOnly?: boolean;
}

const PopConfirm: React.FC<Props> = ({
  cancelText,
  children,
  confirmText,
  disabled,
  onCancel = () => null,
  onConfirm = () => null,
  placement = "top",
  confirmButtonOnly,
  title,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [triggerElement, setTriggerElement] =
    useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleTriggerClickInside = useCallback(() => {
    setIsPopupVisible(true);
  }, []);

  const handleTriggerClickOutside = useCallback(() => {
    setIsPopupVisible(false);
  }, []);

  useClickOutside({
    onClickOutside: handleTriggerClickOutside,
    ref: containerRef,
  });

  const popperModifiers = useMemo(
    () => [
      {
        name: "arrow",
        options: {
          element: arrowRef,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
    [arrowRef]
  );

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    triggerElement,
    popperElement,
    { modifiers: popperModifiers, placement }
  );

  const clonedChildren = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (!React.isValidElement(child) || disabled) {
          return child;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return React.cloneElement(child as any, {
          onClick: () => {
            if (child.props.onClick) {
              child.props.onClick();
            }

            handleTriggerClickInside();
          },
          ref: setTriggerElement,
        });
      }),
    [children, disabled, handleTriggerClickInside, setTriggerElement]
  );

  const handleCancel = useCallback(() => {
    onCancel();

    setIsPopupVisible(false);
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();

    setIsPopupVisible(false);
  }, [onConfirm]);

  return (
    <>
      {clonedChildren}
      {!disabled && isPopupVisible && (
        <Portal>
          <div
            className={styles.popConfirm}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...popperAttributes.popper}
          >
            <div className={styles.popConfirmContent} ref={containerRef}>
              {title}

              <div className={styles.popConfirmButtons}>
                {!confirmButtonOnly && (
                  <Button
                    className={styles.popConfirmButtonsCancel}
                    onClick={handleCancel}
                    size="sm"
                    type="basic"
                  >
                    {cancelText || t("common.cancel")}
                  </Button>
                )}

                <Button
                  onClick={handleConfirm}
                  size="sm"
                  type={confirmButtonOnly ? "basic" : "danger"}
                >
                  {confirmText || t("common.yes")}
                </Button>
              </div>
            </div>

            <div
              className={styles.popConfirmArrow}
              ref={setArrowRef}
              style={popperStyles.arrow}
              {...popperAttributes.arrow}
            />
          </div>
        </Portal>
      )}
    </>
  );
};

export default PopConfirm;

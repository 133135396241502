import { useCallback, useEffect, useMemo, useState } from "react";

import { ApiHelpInfosGet, useGetHelpInfoQuery } from "services/api/helpInfos";
import { useAppDispatch, useAppSelector } from "stores";
import {
  BannerItem,
  bannersUpdated,
  selectBanners,
} from "stores/features/appBuilder/banners";
import { selectPrivateKey } from "stores/features/project";
import { getCookie, setCookie } from "utils/cookie";

const getBannerId = (privateKey: string, bannerId: string) =>
  `banner_${privateKey}_${bannerId}`;

export const useNotificationBanners = () => {
  const dispatch = useAppDispatch();
  const privateKey = useAppSelector(selectPrivateKey);
  const { data: helpInfo = {} as ApiHelpInfosGet } =
    useGetHelpInfoQuery(undefined);

  const appBanners = useAppSelector(selectBanners);

  const [banners, setBanners] = useState<BannerItem[]>([]);

  const platformBanners = useMemo(
    () => helpInfo.banners || [],
    [helpInfo.banners]
  );

  const isBannerVisible = useCallback(
    (item: BannerItem) => {
      // recently closed
      if (!item) return false;
      const { startDate, endDate } = item.display || {};
      const now = Date.now();

      // outdated
      if ((startDate && now < startDate) || (endDate && now > endDate)) {
        return false;
      }

      // no cookie
      return !getCookie(getBannerId(privateKey, item.id));
    },
    [privateKey]
  );

  useEffect(() => {
    const sortedBanners: BannerItem[] = [];

    const maxLength = Math.max(platformBanners.length, appBanners.length);
    for (let i = 0; i < maxLength; i += 1) {
      if (isBannerVisible(platformBanners[i])) {
        sortedBanners.push(platformBanners[i]);
      }
      if (isBannerVisible(appBanners[i])) {
        sortedBanners.push(appBanners[i]);
      }
    }

    setBanners(sortedBanners);
  }, [appBanners, isBannerVisible, platformBanners]);

  const closeBanner = useCallback(
    (item: BannerItem) => {
      setCookie(getBannerId(privateKey, item.id), true, item.display?.cookie);
      dispatch(bannersUpdated(banners.filter((i) => i.id !== item.id)));
    },
    [banners, dispatch, privateKey]
  );

  const closeClaimAppBanner = useCallback(() => {
    const claimAppBanner = banners.find((i) => i.button?.uri === "claim");
    if (claimAppBanner) {
      closeBanner(claimAppBanner);
    }
  }, [banners, closeBanner]);

  return { banners, closeBanner, closeClaimAppBanner };
};

import { camelize } from "humps";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { useAppSelector } from "stores";
import { selectOverview } from "stores/features/appBuilder/overview";
import { SECTION } from "utils/constants";

const useAppSection = () => {
  const router = useRouter();
  const overview = useAppSelector(selectOverview);
  const { query } = router;

  return useMemo(() => {
    let sections = (query.sections as string[] | undefined) || [];

    // Backups
    if (query.privateKey) {
      sections = sections.map((section) => camelize(section));

      if (!Object.values(SECTION).includes(sections[0] as SECTION)) {
        sections[0] = SECTION.overview;
      }
      if (sections[0] === SECTION.access) {
        sections[0] = SECTION.manage;
      }
    } else {
      const privateKey = sections[0];
      sections = sections.slice(1).map((section) => camelize(section));

      if (!Object.values(SECTION).includes(sections[0] as SECTION)) {
        sections[0] = privateKey ? SECTION.manage : SECTION.overview;
      }
      if (!overview.organizationName && sections[0] === SECTION.access) {
        sections[0] = SECTION.manage;
      }
    }

    return sections as [SECTION, ...string[]];
  }, [query.sections, query.privateKey, overview.organizationName]);
};

export default useAppSection;

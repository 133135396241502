/* eslint-disable react/destructuring-assignment */

import React, { ForwardedRef, forwardRef } from "react";

import { useAppSelector } from "stores";
import { selectIsBackupPreview } from "stores/features/backups";

import { BaseProps, InputChangeEvent } from "./constants";
import CheckBoxInput, { CheckBoxInputProps } from "./inputs/CheckBoxInput";
import CodeInput, { CodeInputProps } from "./inputs/CodeInput";
import ColorInput, { ColorInputProps } from "./inputs/ColorInput";
import FileInput, { FileInputProps } from "./inputs/FileInput";
import ImageInput, { ImageInputProps } from "./inputs/ImageInput";
import NumberInput, { NumberInputProps } from "./inputs/NumberInput";
import RadioInput, { RadioInputProps, RadioValue } from "./inputs/RadioInput";
import SliderInput, { SliderInputProps } from "./inputs/SliderInput";
import TextAreaInput, { TextAreaInputProps } from "./inputs/TextAreaInput";
import TextInput, { TextInputProps } from "./inputs/TextInput";
import UrlInput, { UrlInputProps } from "./inputs/UrlInput";

export type InputProps = BaseProps &
  (
    | CheckBoxInputProps
    | CodeInputProps
    | ColorInputProps
    | FileInputProps
    | ImageInputProps
    | NumberInputProps
    | RadioInputProps
    | SliderInputProps
    | TextAreaInputProps
    | TextInputProps
    | UrlInputProps
  );

const Input: React.FC<InputProps> = forwardRef((props, ref) => {
  const isBackupPreview = useAppSelector(selectIsBackupPreview);

  if (props.type === "code") {
    return (
      <CodeInput {...props} readOnly={props.readOnly || isBackupPreview} />
    );
  } else if (props.type === "color") {
    return (
      <ColorInput {...props} disabled={props.disabled || isBackupPreview} />
    );
  } else if (props.type === "file") {
    return <FileInput {...props} />;
  } else if (props.type === "image") {
    return (
      <ImageInput {...props} disabled={props.disabled || isBackupPreview} />
    );
  } else if (props.type === "number") {
    return (
      <NumberInput
        ref={ref as ForwardedRef<HTMLInputElement>}
        {...props}
        disabled={props.disabled || isBackupPreview}
        readOnly={props.readOnly || isBackupPreview}
      />
    );
  } else if (props.type === "radio") {
    return <RadioInput {...props} />;
  } else if (props.type === "checkbox") {
    return <CheckBoxInput {...props} />;
  } else if (props.type === "slider") {
    return (
      <SliderInput {...props} disabled={props.disabled || isBackupPreview} />
    );
  } else if (
    props.type === "email" ||
    props.type === "password" ||
    props.type === "text"
  ) {
    return (
      <TextInput
        ref={ref as ForwardedRef<HTMLInputElement>}
        {...props}
        readOnly={props.readOnly || isBackupPreview}
      />
    );
  } else if (props.type === "textarea") {
    return (
      <TextAreaInput
        ref={ref as ForwardedRef<HTMLTextAreaElement>}
        {...props}
        readOnly={props.readOnly || isBackupPreview}
      />
    );
  } else if (props.type === "url") {
    return (
      <UrlInput
        ref={ref as ForwardedRef<HTMLInputElement>}
        {...props}
        readOnly={props.readOnly || isBackupPreview}
      />
    );
  }

  return null;
});

export default Input;

export type { InputChangeEvent, RadioValue };

import classNames from "classnames";
import Trans from "next-translate/Trans";
import React from "react";

import IconRadioUncheck from "assets/images/icons/IconRadioUncheck";
import IconRadioCheck from "assets/images/icons/IconRadioUncheck copy";
import Button, { ButtonSize } from "components/common/Button";
import PopConfirm from "components/common/PopConfirm";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface RadioValue<T = string> {
  cancelText?: string;
  confirm?: boolean;
  confirmText?: string;
  confirmTitle?: React.ReactNode;
  label: React.ReactNode;
  value: T | null;
}

export interface RadioInputProps {
  allowBlank?: boolean;
  allowOnPreviewMode?: boolean;
  full?: boolean;
  itemClassName?: string;
  itemContainerClassName?: string;
  itemStyles?: React.CSSProperties[];
  onChange?: (event: InputChangeEvent<string | null>) => void;
  options: RadioValue[];
  type: "radio";
  value?: string | null;
  vertical?: boolean;
  horizontal?: boolean;
  size?: ButtonSize;
  popUpMessageOnly?: boolean;
  radioLableClassName?: string;
}

type Props = BaseProps & RadioInputProps;

const RadioInput: React.FC<Props> = ({
  allowBlank,
  allowOnPreviewMode,
  className,
  disabled,
  id,
  itemClassName,
  itemContainerClassName,
  inline,
  label,
  labelClassName,
  labelSize,
  name,
  onChange = () => null,
  options,
  value,
  vertical,
  horizontal,
  size,
  full,
  popUpMessageOnly,
  itemStyles = [],
  radioLableClassName,
}) => {
  const handleChange = (option: RadioValue) => {
    if (allowBlank && value === option.value) {
      onChange({ id, name, value: null });
    } else {
      onChange({ id, name, value: option.value });
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        htmlFor={id}
        label
        size={labelSize}
      >
        {label}
      </InputLabel>

      <div
        className={classNames(
          styles.radioItemContainer,
          vertical && styles.vertical,
          horizontal && styles.horizontal,
          full && styles.full,
          itemContainerClassName
        )}
      >
        {options.map((option, index) => (
          <PopConfirm
            cancelText={option.cancelText}
            confirmText={option.confirmText}
            disabled={!option.confirm}
            key={option.value}
            onConfirm={() =>
              popUpMessageOnly && option.value === "all"
                ? undefined
                : handleChange(option)
            }
            title={option.confirmTitle}
            confirmButtonOnly={popUpMessageOnly && option.value === "all"}
          >
            <Button
              allowOnPreviewMode={allowOnPreviewMode}
              className={classNames(
                styles.radioItem,
                {
                  [styles.lg]: size === "lg",
                  [styles.md]: size === "md",
                  [styles.allowBlank]: allowBlank,
                  [styles.vertical]: vertical,
                  [styles.horizontal]: horizontal,
                  [styles.full]: full,
                },
                itemClassName
              )}
              style={itemStyles[index]}
              onClick={option.confirm ? undefined : () => handleChange(option)}
              disabled={disabled}
            >
              <div className={styles.radioButtonIcon}>
                {option.value === value ? (
                  <IconRadioCheck />
                ) : (
                  <IconRadioUncheck />
                )}
              </div>
              <span
                className={classNames(styles.radioLabel, radioLableClassName)}
              >
                <Trans
                  components={{
                    superscript: (
                      <div className={styles.superscript}>placeholder</div>
                    ),
                  }}
                  i18nKey={option.label as string}
                />
              </span>
            </Button>
          </PopConfirm>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;

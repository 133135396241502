/* eslint-disable global-require */

import dynamic from "next/dynamic";
import React from "react";

import type { IAceEditorProps } from "react-ace";

const AceEditor = dynamic(
  async () => {
    const ace = await import("react-ace");
    require("ace-builds/src-noconflict/ext-searchbox");
    require("ace-builds/src-noconflict/mode-css");
    require("ace-builds/src-noconflict/mode-html");
    require("ace-builds/src-noconflict/mode-json");
    require("ace-builds/src-noconflict/mode-javascript");
    require("ace-builds/src-noconflict/mode-xml");
    require("ace-builds/src-noconflict/theme-tomorrow");
    return ace;
  },
  { ssr: false }
);

const CodeEditor: React.FC<IAceEditorProps> = (props) => (
  <AceEditor {...props} />
);

export default CodeEditor;

import Slider from "@mui/material/Slider";
import classNames from "classnames";
import React, { useCallback } from "react";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface SliderInputProps {
  max: number;
  min: number;
  onChange?: (event: InputChangeEvent<number>) => void;
  renderValue?: (value: number | null | undefined) => React.ReactNode;
  sliderClassName?: string;
  step: number;
  type: "slider";
  value?: number | null;
}

type Props = BaseProps & SliderInputProps;

const SliderInput: React.FC<Props> = ({
  className,
  disabled,
  id,
  inline,
  label,
  labelClassName,
  labelSize,
  max,
  min,
  name,
  onChange = () => null,
  renderValue,
  sliderClassName,
  step,
  value,
}) => {
  const handleChange = useCallback(
    (_e, nextValue) => {
      onChange({ id, name, value: nextValue });
    },
    [id, name, onChange]
  );

  return (
    <label
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
      htmlFor={id}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        size={labelSize}
      >
        {label} {renderValue ? renderValue(value) : value}
      </InputLabel>

      <Slider
        className={classNames(styles.sliderInput, sliderClassName)}
        max={max}
        min={min}
        onChange={handleChange}
        step={step}
        value={value ?? undefined}
        disabled={disabled}
      />
    </label>
  );
};

export default SliderInput;

import { useEffect, useState } from "react";

import { useAppDispatch } from "stores";
import { uiUpdated } from "stores/features/ui";

const useWidget = () => {
  const dispatch = useAppDispatch();
  const [hideHubSpotWidget, setHideHubSpotWidget] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window &&
      window.localStorage &&
      typeof window.localStorage !== "undefined"
    ) {
      const storedValue = window.localStorage.getItem(
        "recent_licensed_app_link"
      );
      if (storedValue !== null) {
        setHideHubSpotWidget(storedValue);
        // Groove Id for customers
        dispatch(
          uiUpdated({ grooveId: "22f64306-3ad3-4c15-919f-6b25abdd7275" })
        );
      } else {
        // Groove Id for non customers
        dispatch(
          uiUpdated({ grooveId: "22f64306-3ad3-4c15-919f-6b25abdd7275" })
        );
      }
    }
  }, [dispatch]);
  return hideHubSpotWidget;
};

export default useWidget;

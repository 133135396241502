/* eslint-disable no-bitwise */

const getHashFromString = (input: string) => {
  let hash = 0;
  for (let i = 0; i < input.length; i += 1) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
};

const normalize = (hash: number, min: number, max: number) =>
  Math.floor((hash % (max - min)) + min);

export const getColorFromString = (input: string) => {
  const hash = getHashFromString(input);

  const hRange = [0, 360];
  const sRange = [50, 75];
  const lRange = [25, 50];
  const h = normalize(hash, hRange[0], hRange[1]);
  const s = normalize(hash, sRange[0], sRange[1]);
  const l = normalize(hash, lRange[0], lRange[1]);

  return `hsl(${h}, ${s}%, ${l}%)`;
};

import React, { useCallback, useMemo } from "react";

import ColorPickerInteractive from "./ColorPickerInteractive";
import { Color, ColorPickerCallback } from "./constants";
import styles from "./styles.module.scss";
import { getAlphaCoordinates, toColor } from "./utils";

export interface AlphaProps {
  readonly width: number;
  readonly color: Color;
  readonly onChange: ColorPickerCallback;
  readonly onChangeComplete?: ColorPickerCallback;
}

const ColorPickerAlpha: React.FC<AlphaProps> = ({
  width,
  color,
  onChange,
  onChangeComplete,
}) => {
  const position = useMemo(
    () => getAlphaCoordinates(color.hsv.a ?? 1, width + 20),
    [color.hsv.a, width]
  );

  const updateColor = useCallback(
    ({ x, complete }) => {
      const newColor = toColor("hsv", {
        ...color.hsv,
        a: x / (width + 20),
      });
      onChange(newColor);
      if (complete && onChangeComplete) onChangeComplete(newColor);
    },
    [color.hsv, onChange, onChangeComplete, width]
  );

  const rgb = useMemo(
    () => `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}`,
    [color.rgb]
  );

  const rgba = useMemo(() => `${rgb}, ${color.rgb.a ?? 1}`, [rgb, color.rgb.a]);

  const positionX = useMemo(() => -position - 2, [position]);

  return (
    <ColorPickerInteractive
      className={styles.rcpAlpha}
      onChange={updateColor}
      style={{
        background: `linear-gradient(to right, rgba(${rgb}, 0), rgba(${rgb}, 1)) top left / auto auto,
              conic-gradient(#666 0.25turn, #999 0.25turn 0.5turn, #666 0.5turn 0.75turn, #999 0.75turn) top left / 12px 12px
              repeat`,
      }}
    >
      <div
        className={styles.rcpAlphaCursor}
        style={{
          left: position,
          background: `linear-gradient(to right, rgba(${rgba}), rgba(${rgba})) top left / auto auto,
                conic-gradient(#666 0.25turn, #999 0.25turn 0.5turn, #666 0.5turn 0.75turn, #999 0.75turn) ${positionX}px 2px / 12px 12px
                repeat`,
        }}
      />
    </ColorPickerInteractive>
  );
};

export default ColorPickerAlpha;

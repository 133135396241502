import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  color?: string;
  disabled?: boolean;
  size?: "lg" | "md" | "sm";
  isFullScreen?: boolean;
}

const Loader: React.FC<Props> = ({
  className,
  color = "#1A100B",
  disabled,
  size = "md",
  isFullScreen,
}) =>
  disabled ? null : (
    <div className={classNames(className, isFullScreen && styles.fullScreen)}>
      <div
        className={classNames(styles.loader, {
          [styles.lg]: size === "lg",
          [styles.sm]: size === "sm",
        })}
        style={{ borderColor: color }}
      />
    </div>
  );

export default Loader;

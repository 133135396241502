import { BannerItem } from "stores/features/appBuilder/banners";

import apiSlice from ".";
import { TagType } from "./constants";

export interface ApiHelpInfosItem {
  text: string;
  slug?: string;
}

export interface ApiHelpInfosGet {
  banners?: BannerItem[];
  pushNotifications: Record<
    | "androidNotificationIcon"
    | "applicationId"
    | "autoRegister"
    | "customNotificationSounds"
    | "enableOneSignal" // Missing
    | "iosNotificationExtension"
    | "ios-soft-prompt" // Obsolete
    | "iosSoftPromptNewWebBuilder"
    | "personalizedPush"
    | "pushRegistrationAndPrivacy",
    ApiHelpInfosItem
  >;
  branding: Record<
    | "appIcon"
    | "brandingOverview"
    | "splashScreen"
    | "themeColors"
    | "statusBar",
    ApiHelpInfosItem
  >;
  interface: Record<
    | "advancedOfflineFunctionality"
    | "appInterfaceOverview"
    | "darkMode"
    | "fontScaling"
    | "fullScreen"
    | "iosSplitView"
    | "keepScreenOn"
    | "maximumWindows"
    | "nativePageTransitions"
    | "offlinePage"
    | "pinchToZoom" // Missing
    | "pullToRefresh"
    | "screenBrightness"
    | "screenOrientation"
    | "swipeGestures"
    | "viewportWidth",
    ApiHelpInfosItem
  >;
  linkHandling: Record<
    | "deepLinking"
    | "internalVsExternalLinks"
    | "linkHandlingOverview"
    | "newWindows"
    | "urlSchemeProtocol",
    ApiHelpInfosItem
  >;
  permissions: Record<
    | "appleAtt"
    | "backgroundAudio"
    | "downloadsDirectory"
    | "locationServices"
    | "permissionsOverview"
    | "webRtc",
    ApiHelpInfosItem
  >;
  gettingStarted: Record<string, never>;
  webOverrides: Record<
    | "customCss"
    | "customHeaders"
    | "customUserAgent"
    | "hidePageElements"
    | "websiteOverridesOverview",
    ApiHelpInfosItem
  >;
  nativeNavigation: Record<
    | "addCustomButtons"
    | "autoNewWindows"
    | "bottomTabBar"
    | "contextualNavigationToolbar"
    | "dynamicMenuItems"
    | "dynamicTabMenu"
    | "dynamicTitles"
    | "nativeNavigationOverview"
    | "refreshButton"
    | "searchBarForm"
    | "sidebarConfiguration"
    | "sidebarNavigationMenu"
    | "stylingBottomTabBar"
    | "stylingSidebar"
    | "stylingTopNavigationBar"
    | "tabConfiguration"
    | "topNavigationBar",
    ApiHelpInfosItem
  >;
  nativePlugins: Record<
    | "androidBiometricAuth"
    | "androidRootDetection"
    | "facebookAppEvents"
    | "microsoftIntune"
    | "nfcTagScanner"
    | "socialLogin",
    ApiHelpInfosItem
  >;
  buildTest: Record<
    | "appConfigurationAppconfigjson"
    | "appDownload"
    | "appIdentifiers"
    | "developmentTools"
    | "googleServices"
    | "gonativeBuildPlatform"
    | "mergeLatestGonativeUpdates",
    ApiHelpInfosItem
  >;
  publishing: Record<"publishingService", ApiHelpInfosItem>;
  troubleshooting: Record<string, never>;
  goNativeJavaScriptBridge: Record<string, never>;
  changelog: Record<string, never>;
  overview: Record<
    | "appName"
    | "contact"
    | "email"
    | "privateManagementUrl" // Missing
    | "publicSharingUrl"
    | "websiteUrl",
    ApiHelpInfosItem
  >;
}

const helpInfosApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getHelpInfo: build.query<ApiHelpInfosGet, void>({
      providesTags: [TagType.HelpInfo],
      query: () => ({
        url: "appbuilder/helpInfo.json",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetHelpInfoQuery } = helpInfosApi;

export default helpInfosApi;

import { useMemo } from "react";

import { useAppSelector } from "stores";
import {
  selectLastSavedTime,
  selectLocalLastSavedTime,
} from "stores/features/ui";

const useIsAppBuilderChanged = () => {
  const localLastSavedTime = useAppSelector(selectLocalLastSavedTime);
  const lastSavedTime = useAppSelector(selectLastSavedTime);
  const isAppBuilderChanged = useMemo(() => {
    const newSavedTime = new Date(lastSavedTime || "");
    const currentSavedTime = new Date(localLastSavedTime || "");
    return !!lastSavedTime && newSavedTime > currentSavedTime;
  }, [lastSavedTime, localLastSavedTime]);

  return { isAppBuilderChanged };
};

export default useIsAppBuilderChanged;

import React, { useCallback, useState } from "react";

import ColorPickerAlpha from "./ColorPickerAlpha";
import ColorPickerFields from "./ColorPickerFields";
import ColorPickerHue from "./ColorPickerHue";
import ColorPickerSaturation from "./ColorPickerSaturation";
import { Color, ColorPickerCallback } from "./constants";
import styles from "./styles.module.scss";
import { getHueCoordinates } from "./utils";

export interface ColorPickerProps {
  readonly width: number;
  readonly height?: number;
  readonly color: Color;
  readonly onChange: ColorPickerCallback;
  readonly onChangeComplete?: ColorPickerCallback;
  readonly suggestedColors?: string[];
  readonly hideHEX?: boolean;
  readonly hideRGB?: boolean;
  readonly hideHSV?: boolean;
  readonly alpha?: boolean;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  width,
  height = width,
  color,
  onChange,
  onChangeComplete,
  suggestedColors = [],
  hideHEX = false,
  hideRGB = false,
  hideHSV = false,
  alpha = false,
}) => {
  const [huePosition, setHuePosition] = useState(
    getHueCoordinates(color.hsv.h, width - 40)
  );

  const handleInputChange = useCallback(
    (nextColor: Color) => {
      setHuePosition(getHueCoordinates(nextColor.hsv.h, width - 40));
      onChange(nextColor);
    },
    [onChange, width]
  );

  return (
    <div className={styles.rcp} style={{ width }}>
      <ColorPickerSaturation
        hue={(huePosition * 360) / (width - 40)}
        width={width}
        height={height}
        color={color}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
      />
      <div className={styles.rcpBody}>
        <ColorPickerHue
          huePosition={huePosition}
          setHuePosition={setHuePosition}
          width={width - 40}
          color={color}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
        />
        {alpha && (
          <ColorPickerAlpha
            width={width - 40}
            color={color}
            onChange={onChange}
            onChangeComplete={onChangeComplete}
          />
        )}
        <ColorPickerFields
          color={color}
          hideHEX={hideHEX}
          hideRGB={hideRGB}
          hideHSV={hideHSV}
          alpha={alpha}
          onChange={handleInputChange}
          suggestedColors={suggestedColors}
        />
      </div>
    </div>
  );
};

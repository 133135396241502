import { useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";

const useIsLicensed = () => {
  const license = useAppSelector(selectLicense);
  return ["active", "enterprise", "nonProduction", "fullService"].includes(
    license.app.status || ""
  );
};

export default useIsLicensed;

import { useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";

const useIsEnterprise = () => {
  const license = useAppSelector(selectLicense);
  return (
    license.app.status === "enterprise" || license.app.support === "enterprise"
  );
};

export default useIsEnterprise;

import Script from "next/script";
import React from "react";
import { useLocalStorage } from "react-use";

import { useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";
import { selectEmail } from "stores/features/appBuilder/overview";
import { selectGrooveId, selectSupportGrooveId } from "stores/features/ui";
import { useAnalytics, useIsLicensed } from "utils/hooks";
import { storageKeys } from "utils/storage";
import { GrooveLinkType } from "utils/support";
import urls from "utils/urls";

interface Props {
  hotJarEnabled?: boolean;
  grooveEnabled?: boolean;
}

const AppScripts: React.FC<Props> = ({ hotJarEnabled, grooveEnabled }) => {
  const isLicensed = useIsLicensed();
  const license = useAppSelector(selectLicense);
  const defaultGrooveId = useAppSelector(selectGrooveId);
  const supportGrooveId = useAppSelector(selectSupportGrooveId);
  const [recentEnterpriseApp = {}] = useLocalStorage<GrooveLinkType>(
    storageKeys.enterpriseApp()
  );
  const [recentFullServiceApp = {}] = useLocalStorage<GrooveLinkType>(
    storageKeys.fullserviceApp()
  );

  const enableAnalytics = useAnalytics();

  const grooveId =
    (isLicensed &&
      ["basic", "premium", "custom", "enterprise", "fullService"].includes(
        license.app.support
      )) ||
    recentEnterpriseApp.link ||
    recentFullServiceApp.link
      ? supportGrooveId
      : defaultGrooveId;
  const email = useAppSelector(selectEmail);

  return (
    <>
      <Script
        id="twitter-wjs"
        src="//platform.twitter.com/widgets.js"
        strategy="lazyOnload"
      />
      {/*
    <Script
      src="https://js.hsadspixel.net/fb.js"
      type="text/javascript"
      id="hs-ads-pixel-20848215"
      data-ads-portal-id="20848215"
      data-ads-env="prod"
      data-loader="hs-scriptloader"
      data-hsjs-portal="20848215"
      data-hsjs-env="prod"
      data-hsjs-hublet="na1"
    />
    */}
      <Script
        src="https://js.hs-analytics.net/analytics/1638265800000/20848215.js"
        type="text/javascript"
        id="hs-analytics"
      />
      {/* <Script src="https://connect.facebook.net/en_US/fbevents.js" /> */}
      <Script src={urls.static("main.js")} />
      {/* Google Analytics */}
      {enableAnalytics && (
        <>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-JHTZNFF295" />
          <Script
            id="app-footer-scripts-google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
      window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
           if (typeof window.gtag === "function") {
          gtag('js', new Date());
          gtag('config', 'G-JHTZNFF295');
          gtag('config', 'AW-10785461575');
          }
        `,
            }}
          />
        </>
      )}

      {/* G2 */}
      {enableAnalytics && (
        <Script
          id="g2-script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
      (function(c, p, d, u, id, i) {
        id = ${JSON.stringify(email)};
        u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?';
        p = encodeURI(p) + '&e=' + id;
        i = document.createElement('script');
        i.type = 'application/javascript';
        i.async = true;
        i.src = u + p;
        d.getElementsByTagName('head')[0].appendChild(i);
      })("1010646", document.location.href, document);
    `,
          }}
        />
      )}
      {/* LinkedIn Pixel */}
      {enableAnalytics && (
        <>
          <Script
            id="linkedin-parnter-id-script"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
         _linkedin_partner_id = "5422756";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `,
            }}
          />
          <Script
            id="linkedin-insight-script"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
       (function(l) {
         if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
         window.lintrk.q=[]}
         var s = document.getElementsByTagName("script")[0];
         var b = document.createElement("script");
         b.type = "text/javascript";b.async = true;
         b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
         s.parentNode.insertBefore(b, s);})(window.lintrk);
  `,
            }}
          />
        </>
      )}
      {/* Twitter */}
      <Script
        id="app-footer-scripts-twitter"
        dangerouslySetInnerHTML={{
          __html: `
        if (!window.isCrawler) {
            document.addEventListener("DOMContentLoaded", function() {
              ! function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (!d.getElementById(id)) {
                      js = d.createElement(s);
                      js.id = id;
                      js.src = "//platform.twitter.com/widgets.js";
                      fjs.parentNode.insertBefore(js, fjs);
                  }
              }(document, "script", "twitter-wjs");
            });
        }
      `,
        }}
      />
      {/* HubSpot */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/20848215.js"
      />
      {/* Hotjar */}
      {hotJarEnabled && (
        <>
          <Script src="https://static.hotjar.com/c/hotjar-2628531.js?sv=6" />
          <Script
            id="app-footer-scripts-hotjar"
            dangerouslySetInnerHTML={{
              __html: `
       (function(h,o,t,j,a,r){ 
           h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; 
           h._hjSettings={hjid:2628531,hjsv:6}; 
           a=o.getElementsByTagName('head')[0]; 
           r=o.createElement('script');
           r.async=1; 
           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; 
           a.appendChild(r); 
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); 
     `,
            }}
          />
        </>
      )}
      {/* Microsoft */}
      {enableAnalytics && (
        <Script
          id="microsoft-script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
      (function(w,d,t,r,u)
      {
          var f,n,i;
          w[u]=w[u]||[],f=function()
          {
              var o={ti:"187105244", enableAutoSpaTracking: true};
              o.q=w[u];
              if (typeof UET !== 'undefined') {
                  w[u] = new UET(o);
                  w[u].push("pageLoad");
              }
          },
          n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
          {
              var s=this.readyState;
              s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
          },
          i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
      })
      (window,document,"script","//bat.bing.com/bat.js","uetq");
    `,
          }}
        />
      )}

      {/* Meta Pixel */}
      {enableAnalytics && (
        <>
          <Script
            id="meta-pixel-script"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1522564151625375');
            fbq('track', 'PageView');
          `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              alt=""
              src="https://www.facebook.com/tr?id=1522564151625375&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      )}

      {/* Groove */}
      {grooveEnabled && (
        <Script
          id="app-footer-scripts-groove"
          dangerouslySetInnerHTML={{
            __html: `
      !(function (e, t) {
        if (!e.groove) {
          var i = function (e, t) {
              return Array.prototype.slice.call(e, t);
            },
            a = {
              widget: null,
              loadedWidgets: {},
              classes: {
                Shim: null,
                Embeddable: function () {
                  (this._beforeLoadCallQueue = []),
                    (this.shim = null),
                    (this.finalized = !1);
                  var e = function (e) {
                    var t = i(arguments, 1);
                    if (this.finalized) {
                      if (!this[e])
                        throw new TypeError(e + "() is not a valid widget method");
                      this[e].apply(this, t);
                    } else this._beforeLoadCallQueue.push([e, t]);
                  };
                  (this.initializeShim = function () {
                    a.classes.Shim && (this.shim = new a.classes.Shim(this));
                  }),
                    (this.exec = e),
                    (this.init = function () {
                      e.apply(this, ["init"].concat(i(arguments, 0))),
                        this.initializeShim();
                    }),
                    (this.onShimScriptLoad = this.initializeShim.bind(this)),
                    (this.onload = void 0);
                },
              },
              scriptLoader: {
                callbacks: {},
                states: {},
                load: function (e, i) {
                  if ("pending" !== this.states[e]) {
                    this.states[e] = "pending";
                    var a = t.createElement("script");
                    (a.id = e),
                      (a.type = "text/javascript"),
                      (a.async = !0),
                      (a.src = i);
                    var s = this;
                    a.addEventListener(
                      "load",
                      function () {
                        (s.states[e] = "completed"),
                          (s.callbacks[e] || []).forEach(function (e) {
                            e();
                          });
                      },
                      !1
                    );
                    var n = t.getElementsByTagName("script")[0];
                    n.parentNode.insertBefore(a, n);
                  }
                },
                addListener: function (e, t) {
                  "completed" !== this.states[e]
                    ? (this.callbacks[e] || (this.callbacks[e] = []),
                      this.callbacks[e].push(t))
                    : t();
                },
              },
              createEmbeddable: function () {
                var t = new a.classes.Embeddable();
                return e && e.Proxy
                  ? new Proxy(t, {
                      get: function (e, t) {
                        return e instanceof a.classes.Embeddable
                          ? Object.prototype.hasOwnProperty.call(e, t) ||
                            "onload" === t
                            ? e[t]
                            : function () {
                                e.exec.apply(e, [t].concat(i(arguments, 0)));
                              }
                          : e[t];
                      },
                    })
                  : t;
              },
              createWidget: function () {
                var e = a.createEmbeddable();
                return (
                  a.scriptLoader.load(
                    "groove-script",
                    "https://${grooveId}.widget.cluster.groovehq.com/api/loader"
                  ),
                  a.scriptLoader.addListener(
                    "groove-iframe-shim-loader",
                    e.onShimScriptLoad
                  ),
                  e
                );
              },
            };
          e.groove = a;
        }
      })(window, document);
      window.groove.widget = window.groove.createWidget();
      window.groove.widget.init("${grooveId}", {});
      `,
          }}
        />
      )}
    </>
  );
};

export default AppScripts;

import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";

import { RadioValue } from "components/common/Input";

export default function useRadioOptions<T extends string = string>(
  values: T[],
  translationBaseKey: string
): RadioValue<T>[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      values.map((value) => ({
        label: t(`${translationBaseKey}.${value}`),
        value,
      })),
    [t, translationBaseKey, values]
  );
}

import { useCallback, useEffect } from "react";

function useClickOutside<T extends HTMLElement>({
  onClickOutside,
  ref,
}: {
  onClickOutside: (event: MouseEvent) => void;
  ref: React.RefObject<T>;
}) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!ref || !ref.current || ref.current.contains(event.target as T)) {
        return;
      }

      onClickOutside(event);
    },
    [onClickOutside, ref]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
}

export default useClickOutside;

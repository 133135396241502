import classNames from "classnames";
import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

interface Props {
  averageDelay?: number;
  className?: string;
  completed?: boolean;
  onFinish?: () => void;
}

const ProgressLoader: React.FC<Props> = ({
  averageDelay = 40,
  className,
  completed,
  onFinish = () => null,
}) => {
  const [started, setStarted] = useState(false);

  const [circleRef, setCircleRef] = useState<SVGCircleElement | null>(null);

  useEffect(() => {
    setStarted(true);
  }, []);

  useEffect(() => {
    const ended = (e: TransitionEvent) => {
      if (e.propertyName === "stroke-dashoffset") {
        setTimeout(onFinish, 200);
      }
    };

    if (circleRef) {
      circleRef.addEventListener("transitionend", ended);
    }

    return () => {
      if (circleRef) {
        circleRef.removeEventListener("transitionend", ended);
      }
    };
  }, [circleRef, onFinish]);

  return (
    <div className={classNames(styles.progressLoader, className)}>
      <svg className={styles.progressContainer}>
        <circle className={styles.track} cx="110" cy="110" r="100" />
        <circle
          className={classNames(
            styles.initialProgress,
            started && styles.started
          )}
          cx="110"
          cy="110"
          r="100"
          style={{ transition: `${averageDelay}s ease` }}
        />
        <circle
          ref={setCircleRef}
          className={classNames(
            styles.finalProgress,
            completed && styles.started
          )}
          cx="110"
          cy="110"
          r="100"
        />
      </svg>
    </div>
  );
};

export default ProgressLoader;

import { useAppSelector } from "stores";
import { selectBuild } from "stores/features/appBuilder/build";
import { selectLicense } from "stores/features/appBuilder/license";
import { selectSupport } from "stores/features/appBuilder/support";

import useIsLicensed from "./useIsLicensed";

const useShowBuildExpiryDate = () => {
  const license = useAppSelector(selectLicense);
  const build = useAppSelector(selectBuild);
  const support = useAppSelector(selectSupport);
  const isLicensed = useIsLicensed();

  const showBuildExpiryDate = isLicensed && license.app.support === "none";

  const { buildActive } = build.license;
  const buildExpiryDate = build.license.buildExpiry
    ? new Date(build.license.buildExpiry)
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
        .replace(/,/, ",")
    : undefined;

  const renewalDate = support.expiryDate
    ? new Date(support.expiryDate)
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
        .replace(/,/, ",")
    : undefined;

  return {
    showBuildExpiryDate,
    buildActive,
    buildExpiryDate,
    renewalDate,
  };
};

export default useShowBuildExpiryDate;

/* eslint-disable no-param-reassign */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useRef, useState } from "react";

import ActionButton from "components/common/ActionButton";
import { ButtonSize, ButtonType } from "components/common/Button";
import LoadingModal from "components/common/LoadingModal";
import { useAppSelector } from "stores";
import { selectPrivateKey, selectPublicKey } from "stores/features/project";
import api from "utils/api";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";
import FileInputAudio from "./FileInputAudio";
import { validAudioTypes } from "./FileInputAudio/utils";

export interface FileInputProps {
  autoUpload?: boolean;
  accept?: string[];
  buttonClassName?: string;
  buttonIcon?: React.ReactNode;
  buttonLabel?: React.ReactNode;
  buttonSize?: ButtonSize;
  buttonTitle?: string;
  buttonType?: ButtonType;
  mediaType?: "audio" | "lottieJson";
  onChange?: (event: InputChangeEvent<File>) => void;
  onRemove?: () => void;
  removeTitle?: string;
  type: "file";
  value?: string | null;
}

type Props = BaseProps & FileInputProps;

const FileInput: React.FC<Props> = ({
  accept,
  autoFocus,
  autoUpload,
  buttonClassName,
  buttonIcon,
  buttonLabel,
  buttonSize,
  buttonTitle,
  buttonType = "outlined",
  className,
  disabled,
  id,
  inline,
  label,
  labelClassName,
  labelSize,
  loading,
  mediaType,
  name,
  onChange = () => null,
  onRemove = () => null,
  value,
}) => {
  const privateKey = useAppSelector(selectPrivateKey);
  const publicKey = useAppSelector(selectPublicKey);
  const [isUploading, setIsUploading] = useState(false);
  const [completionCallback, setCompletionCallback] = useState<() => void>();

  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleBrowse = () => {
    inputRef.current?.click();
  };

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event?.target.files || [];
      if (files.length === 0) return;

      let file = files[0];
      let fileName = file.name;

      if (mediaType === "audio" && !validAudioTypes.includes(file.type)) {
        return;
      }

      if (mediaType === "audio") {
        fileName = `custom_sound_${file.name.slice(0, 40)}`;
        file = new File([file], fileName, { type: file.type });
      }

      if (mediaType === "lottieJson") {
        let url: string | undefined;
        let firstFrame: string | undefined;

        try {
          setIsUploading(true);
          setCompletionCallback(undefined);

          url = await api.general.upload({
            publicKey,
            file: files[0],
          });
          firstFrame = await api.animation.getFirstFrame({
            privateKey,
            url,
          });

          setCompletionCallback(() => () => {
            onChange({
              id,
              name,
              value: files[0],
              extras: { firstFrame, url },
            });
            setIsUploading(false);
          });
        } catch {
          setIsUploading(false);
        }
      } else {
        let url: string | undefined;

        if (autoUpload) {
          try {
            setIsUploading(true);
            url = await api.general.upload({
              publicKey,
              file,
            });
          } finally {
            setIsUploading(false);
          }
        }

        onChange({ id, name, value: file, extras: { url } });

        // Allow reupload of the previous file
        event.target.value = "";
      }
    },
    [mediaType, onChange, id, name, publicKey, privateKey, autoUpload]
  );

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
    >
      <InputLabel
        className={classNames(inline && styles.labelInline, labelClassName)}
        size={labelSize}
      >
        {label}
      </InputLabel>

      <ActionButton
        action={buttonTitle || t("components.inputs.upload")}
        className={buttonClassName}
        disabled={disabled || isUploading}
        icon={buttonIcon}
        label={buttonLabel}
        loading={loading || isUploading}
        onClick={handleBrowse}
        size={buttonSize}
        type={buttonType}
      />

      <input
        accept={accept?.join(", ")}
        autoFocus={autoFocus}
        className={styles.fileInput}
        disabled={disabled || isUploading}
        id={id}
        name={name}
        onChange={handleChange}
        ref={inputRef}
        type="file"
      />

      {mediaType === "audio" && (
        <FileInputAudio
          src={value}
          disabled={disabled || isUploading}
          onRemove={onRemove}
        />
      )}

      {mediaType === "lottieJson" && isUploading && (
        <LoadingModal
          isOpen
          averageDelay={10}
          completed={!!completionCallback}
          onFinish={completionCallback}
          title={t("sections.branding.uploadingAnimation")}
          showProgress
        />
      )}
    </div>
  );
};

export default FileInput;

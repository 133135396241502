import classNames from "classnames";
import React, { forwardRef, useCallback } from "react";

import { BaseProps, InputChangeEvent } from "../constants";
import InputLabel from "../InputLabel";
import styles from "../styles.module.scss";

export interface TextAreaInputProps {
  autoCapitalize?: "characters" | "none" | "off" | "on" | "sentences" | "words";
  autoCorrect?: "on" | "off";
  defaultInputField?: boolean;
  onChange?: (event: InputChangeEvent) => void;
  placeholder?: string;
  rows?: number;
  type: "textarea";
  maxLength?: number;
  value?: string | null;
}

type Props = BaseProps & TextAreaInputProps;

const TextAreaInput = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      autoCapitalize,
      autoCorrect,
      autoFocus,
      className,
      disabled,
      id,
      inline,
      defaultInputField,
      label,
      labelClassName,
      labelSize,
      maxLength,
      name,
      onBlur = () => null,
      onChange = () => null,
      onFocus = () => null,
      placeholder,
      readOnly,
      required,
      rows = 4,
      value,
    },
    ref
  ) => {
    const handleBlur = useCallback(() => {
      onBlur(id);
    }, [id, onBlur]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange({ id, name, value: event.target.value });
      },
      [id, name, onChange]
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLTextAreaElement>) => {
        onFocus(id, e);
      },
      [id, onFocus]
    );

    return (
      <label
        className={classNames(
          styles.container,
          { [styles.inline]: inline },
          className
        )}
        htmlFor={id}
      >
        <InputLabel
          className={classNames(inline && styles.labelInline, labelClassName)}
          size={labelSize}
        >
          {label}
        </InputLabel>

        <div className={styles.inputContainer}>
          <textarea
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            autoFocus={autoFocus}
            className={classNames(
              styles.textAreaInput,
              defaultInputField && styles.defaultInputField
            )}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            required={required}
            rows={rows}
            value={value || ""}
          />
        </div>
      </label>
    );
  }
);

export default TextAreaInput;

import { BackupItem } from "services/api/backups";

import { baseAxios } from "./utils";

interface APIGetBackups {
  backups: BackupItem[];
}

export const getBackups = async (privateKey: string) => {
  const response = await baseAxios.get<APIGetBackups>(
    `project/${privateKey}/backups`
  );
  return response.data?.backups || [];
};

import React, { SVGProps } from "react";

const IconNewWindow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 15.75C3.3375 15.75 2.98425 15.6033 2.69025 15.3097C2.39675 15.0157 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39675 2.98425 2.69025 2.69025C2.98425 2.39675 3.3375 2.25 3.75 2.25H9V3.75H3.75V14.25H14.25V9H15.75V14.25C15.75 14.6625 15.6033 15.0157 15.3097 15.3097C15.0157 15.6033 14.6625 15.75 14.25 15.75H3.75ZM7.275 11.775L6.225 10.725L13.2 3.75H10.5V2.25H15.75V7.5H14.25V4.8L7.275 11.775Z"
      fill="currentColor"
    />
  </svg>
);

export default IconNewWindow;

import { baseAxios } from "./utils";

export interface GetFirstFrame {
  privateKey: string;
  url: string;
}

export const getFirstFrame = async (params: GetFirstFrame) => {
  const { privateKey, url } = params;

  const { data } = await baseAxios.post(
    `/project/${privateKey}/splash-animation-frame`,
    { url }
  );
  return data.url;
};

import React, { SVGProps } from "react";

const IconRadioCheck: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33337 12C3.33337 16.9523 7.38109 21 12.3334 21C17.2857 21 21.3334 16.9523 21.3334 12C21.3334 7.04772 17.2857 3 12.3334 3C7.38109 3 3.33337 7.04772 3.33337 12Z"
      stroke="#1A100B"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3334 17C9.58337 17 7.33337 14.75 7.33337 12C7.33337 9.25 9.58337 7 12.3334 7C15.0834 7 17.3334 9.25 17.3334 12C17.3334 14.75 15.0834 17 12.3334 17V17Z"
      fill="#1A100B"
    />
  </svg>
);

export default IconRadioCheck;
